import axios from 'axios';
import { GeoIpResponse, GeoIpResult } from '../../Types';

const getGeoIpData = async (ipAddress: string): Promise<GeoIpResult> => {
    const apiKey = process.env.REACT_APP_ABSTRACT_API_KEY;
    const abstractApiUrl = process.env.REACT_APP_ABSTRACT_API_URL;
    if (!apiKey || !abstractApiUrl) {
        return { data: null, subdivisionCode: null, postalCode: null };
    }

    try {
        const response = await axios.get<GeoIpResponse>(
            `${abstractApiUrl}/?api_key=${apiKey}&ip_address=${ipAddress}`
        );

        const data = response.data;
        const subdivisionCode = `${data.country_code}-${data.region_iso_code}`;
        const postalCode = data.postal_code || '';

        return {
            data,
            subdivisionCode,
            postalCode,
        };
    } catch (error) {
        return { data: null, subdivisionCode: null, postalCode: null };
    }
};

export default getGeoIpData;
