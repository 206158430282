import React from 'react';
import { Line } from 'react-chartjs-2';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
    Filler
} from 'chart.js';

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
    Filler
);

interface DataPoint {
    date: string;
    count: number;
}

interface UserGrowthChartProps {
    data: DataPoint[] | null;
    loading: boolean;
}

const UserGrowthChart: React.FC<UserGrowthChartProps> = ({ data, loading }) => {
    console.log("unsortwed", data);

    // Handle null or undefined data
    if (!data || !Array.isArray(data)) {
        return (
            <div className="relative h-[300px] w-full flex items-center justify-center">
                {loading ? "loading..." : <p className="text-gray-500">No data available</p>}
            </div>
        );
    }

    // Sort data by date
    const sortedData = data.sort((a, b) => new Date(a.date).getTime() - new Date(b.date).getTime());

    // Format date to MMM YYYY
    const formatDate = (dateString: string) => {
        const date = new Date(dateString);
        return date.toLocaleDateString('en-US', { month: 'short', year: 'numeric' });
    };


    const chartData = {
        labels: sortedData.map(item => formatDate(item.date)),
        datasets: [
            {
                label: 'User Growth',
                data: sortedData.map(item => item.count),
                fill: true,
                backgroundColor: 'rgba(42, 145, 157, 0.1)',
                borderColor: '#2a919d',
                tension: 0.4,
                pointRadius: 4,
                pointBackgroundColor: '#2a919d',
                pointBorderColor: '#fff',
                pointBorderWidth: 2,
                pointHoverRadius: 6,
                pointHoverBackgroundColor: '#2a919d',
                pointHoverBorderColor: '#fff',
                pointHoverBorderWidth: 2,
            },
        ],
    };

    const options = {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
            legend: {
                display: false,
            },
            tooltip: {
                backgroundColor: 'rgba(255, 255, 255, 0.9)',
                titleColor: '#3E3F66',
                titleFont: {
                    size: 13,
                    weight: '600',
                },
                bodyColor: '#2a919d',
                bodyFont: {
                    size: 12,
                },
                padding: 12,
                borderColor: 'rgba(42, 145, 157, 0.1)',
                borderWidth: 1,
                displayColors: false,
                callbacks: {
                    title: (tooltipItems: any) => {
                        return tooltipItems[0].label;
                    },
                    label: (context: any) => {
                        return `${context.parsed.y.toLocaleString()} Users`;
                    },
                }
            },
        },
        scales: {
            x: {
                grid: {
                    display: false,
                },
                ticks: {
                    font: {
                        size: 11,
                    },
                    color: '#6B7280',
                },
            },
            y: {
                beginAtZero: true,
                grid: {
                    color: 'rgba(0, 0, 0, 0.05)',
                },
                ticks: {
                    font: {
                        size: 11,
                    },
                    color: '#6B7280',
                    callback: (value: number) => value.toLocaleString(),
                },
            },
        },
        interaction: {
            intersect: false,
            mode: 'index' as const,
        },
        elements: {
            line: {
                borderWidth: 2,
            },
        },
    };

    return (
        <div className="relative h-[300px] w-full">
            <Line data={chartData} options={options as any} />
        </div>
    );
};

export default UserGrowthChart;