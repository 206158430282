import React, { useEffect } from 'react';
import { Doughnut, Bar } from 'react-chartjs-2';
import {
    Chart as ChartJS,
    ArcElement,
    BarElement,
    Tooltip,
    Legend
} from 'chart.js';
import { FaChartPie } from "react-icons/fa";
import { TiChartBarOutline } from "react-icons/ti";

ChartJS.register(
    ArcElement,
    BarElement,
    Tooltip,
    Legend
);

interface AgeGroup {
    range: string;
    count: number;
}

interface DemographicsData {
    ageDistribution: AgeGroup[];
    femaleCount: number;
    maleCount: number;
    totalUsers: number;
}

interface UserDemographicsChartProps {
    data: DemographicsData | null;
    loading: boolean;
}

const UserDemographicsChart: React.FC<UserDemographicsChartProps> = ({ data, loading }) => {
    const [chartType, setChartType] = React.useState<'doughnut' | 'bar'>('doughnut');

    if (!data) {
        return (
            <div className="p-5 h-[300px] flex items-center justify-center">
                {loading ? "loading demographics data ..." : <p className="text-gray-500">No demographics data...</p>}
            </div>
        );
    }

    const colors = [
        'rgba(42, 145, 157, 0.8)',   // Primary color
        'rgba(255, 99, 132, 0.8)',   // Pink
        'rgba(255, 206, 86, 0.8)',   // Yellow
        'rgba(75, 192, 192, 0.8)',   // Teal
        'rgba(153, 102, 255, 0.8)',  // Purple
    ];

    const genderData = {
        labels: ['Male', 'Female'],
        datasets: [{
            label: 'Gender',
            data: [data.maleCount, data.femaleCount],
            backgroundColor: [colors[0], colors[1]],
            borderWidth: 1,
        }],
    };

    const ageData = {
    labels: data.ageDistribution.map(age => age.range),
    datasets: [{
        label: 'Age Group',
        data: data.ageDistribution.map(age => age.count),
        backgroundColor: colors,
        borderWidth: 1,
    }],
};

    const options = {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
            legend: {
                position: 'right' as const,
                labels: {
                    font: {
                        size: 12,
                    },
                    padding: 20,
                    usePointStyle: true,
                    pointStyle: 'circle',
                },
            },
            tooltip: {
                backgroundColor: 'rgba(255, 255, 255, 0.9)',
                titleColor: '#3E3F66',
                titleFont: {
                    size: 13,
                    weight: '600',
                },
                bodyColor: '#2a919d',
                bodyFont: {
                    size: 12,
                },
                padding: 12,
                borderColor: 'rgba(42, 145, 157, 0.1)',
                borderWidth: 1,
                displayColors: true,
                callbacks: {
                    label: (context: any) => {
                        const label = context.label || context.raw.label || '';
                        const value = context.raw || 0;
                        const percentage = ((value / data.totalUsers) * 100).toFixed(1);
                        return `${label}: ${value.toLocaleString()} (${percentage}%)`;
                    },
                },
            },
        },
    };
    

    const handleChartToggle = () => {
        setChartType(chartType === 'doughnut' ? 'bar' : 'doughnut');
    };

    return (
       <div className="bg-white rounded-lg shadow-sm p-6 col-span-2">
        <div className="flex justify-between items-center mb-6">
            <h2 className="text-xl font-semibold text-[#3E3F66]">Demographics</h2>
            <button onClick={handleChartToggle} className="text-sm text-blue-600 hover:underline flex items-center gap-2">
    {chartType === 'doughnut' ? (
        <>
            <TiChartBarOutline className="text-xl" />
            <span>Bar Chart</span>
        </>
    ) : (
        <>
            <FaChartPie className="text-xl" />
            <span>Doughnut Chart</span>
        </>
    )}
</button>
        </div>
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-8">
            <div className="bg-white rounded-lg p-6">
                <h3 className="text-lg font-semibold text-[#3E3F66] mb-4 text-center">Gender Distribution</h3>
                <div className="h-[300px] relative">
                    {chartType === 'doughnut' ? (
                        <Doughnut data={genderData} options={options as any} />
                    ) : (
                        <Bar data={genderData} options={options as any} />
                    )}
                    <div className="absolute bottom-0 left-0 right-0 text-center text-sm text-gray-500">
                        Total Users: {data.totalUsers.toLocaleString()}
                    </div>
                </div>
            </div>
            <div className="bg-white rounded-lg p-6">
                <h3 className="text-lg font-semibold text-[#3E3F66] mb-4 text-center">Age Distribution</h3>
                <div className="h-[300px] relative">
                    {chartType === 'doughnut' ? (
                        <Doughnut data={ageData} options={options as any} />
                    ) : (
                        <Bar data={ageData} options={options as any} />
                    )}
                    <div className="absolute bottom-0 left-0 right-0 text-center text-sm text-gray-500">
                        By Age Group
                    </div>
                </div>
            </div>
        </div>
       </div>
    );
};

export default UserDemographicsChart;
