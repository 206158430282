import React, { useEffect } from 'react';
import '../Assets/Styles/CustomAlert.css';

type CustomAlertProps = {
    message: string;
    type: 'success' | 'error' | 'warning' | 'info';
    onClose?: () => void;
    timeout?: number;
};

const CustomAlert: React.FC<CustomAlertProps> = ({
                                                     message,
                                                     type,
                                                     onClose,
                                                     timeout = 10000
                                                 }) => {
    useEffect(() => {
        if (onClose) {
            const timer = setTimeout(onClose, timeout);
            return () => clearTimeout(timer);
        }
    }, [timeout, onClose]);

    return (
        <div className={`alert alert-${type}`} role="alert">
            <span className="alert-message">{message}</span>
            {onClose && (
                <button onClick={onClose} className="close" aria-label="Close">
                    <span aria-hidden="true">×</span>
                </button>
            )}
        </div>
    );
};

export default CustomAlert;
