import { useEffect, useState } from "react";
import axios from "axios";
import DashboardLayout from "../../../Layouts/PageLayouts/DashboardLayout";
import { GoPlusCircle } from "react-icons/go";
import CreateOpportunitiesModal from "../../../Components/Modals/CreateOpportunitiesModal";
import DeleteModal from "../../../Components/Modals/DeleteModal";
import "../../../Assets/Styles/SurveyList.css";
import CintService from "../../../Api/Routes/Cint/CintService";
import CintOpportunityItem, {
  CintOpportunity,
} from "../../../Components/CintOpportunityItem";
import rightArrowIcon from "../../../Assets/Images/chevron-right.svg";
import leftArrowIcon from "../../../Assets/Images/chevron-left.svg";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { useLanguage } from "../../../Hooks/useLanguage";

const SkeletonLoader = () => (
  <div className="border border-zinc-200 bg-white rounded-xl p-4 w-full mb-4">
    <div className="animate-pulse flex space-x-4">
      <div className="flex-1 space-y-4 py-1">
        <div className="h-4 bg-zinc-200 rounded w-3/4"></div>
        <div className="space-y-2">
          <div className="h-4 bg-zinc-200 rounded"></div>
          <div className="h-4 bg-zinc-200 rounded w-5/6"></div>
        </div>
      </div>
      <div className="rounded-full bg-zinc-200 h-10 w-10"></div>
    </div>
  </div>
);

function CintDashboard() {
  const [loading, setLoading] = useState(false);
  const [result, setResult] = useState<string | null>(null);
  const [currentPage, setCurrentPage] = useState(1);
  const { language } = useLanguage();
  const [metadata, setMetadata] = useState({
    hasNextPage: false,
    hasPreviousPage: false,
    limit: 5,
    page: 1,
    total: 0,
    totalPages: 0
  });
  const [limit] = useState(5);
  const [opportunities, setOpportunities] = useState<CintOpportunity[]>([]);
  const [error, setError] = useState<string | null>(null);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [isDeleteModalOpen, setDeleteModalOpen] = useState(false);
  const cintService = new CintService();
  const [openItemId, setOpenItemId] = useState<number | null>(null);
  const { t } = useTranslation();


  const handleToggle = (projectId: number) => {
    setOpenItemId(openItemId === projectId ? null : projectId);
  };

  const [itemToDelete, setItemToDelete] = useState<number | null>(null);

  const handleDelete = (id: number) => {
    setItemToDelete(id);
    setDeleteModalOpen(true);
  };

  // Add pagination handler
  const handlePageChange = (newPage: number) => {
    if (newPage >= 1 && newPage <= metadata.totalPages && newPage !== currentPage) {
        setCurrentPage(newPage);
    }
};

  const confirmDelete = () => {
    if (itemToDelete !== null) {
      setOpportunities(
        opportunities.filter((opp) => opp.c_project_id !== itemToDelete)
      );
    }
    setDeleteModalOpen(false);
    setItemToDelete(null);
  };

  // Modify `fetchOpportunities` to include query params for pagination
  const fetchOpportunities = async () => {
    try {
      setLoading(true);
      setError(null);
      const response = await cintService.getOpportunities(currentPage, limit);
      console.log('response from user :', response)
      setOpportunities(response.data.data.data || []);
      setMetadata(response.data.data.metadata);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching opportunities:", error);
      setError("Failed to fetch opportunities. Please try again later.");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchOpportunities();
  }, [currentPage]);

  const createOpportunity = async () => {
    setLoading(true);
    setResult(null);

    const options = {
      method: "POST",
      url: "https://api.samplicio.us/supply/opportunities/v1/subscriptions/{supplier_code}",
      headers: {
        "Content-Type": "application/json",
        Authorization:
          process.env.REACT_APP_SAMPLICIO_API_KEY || "YOUR_API_KEY_HERE",
      },
      data: {
        callback: "https://www.callback.com/url",
        include_quotas: true,
        opportunities: [
          {
            country_language: { in: ["eng_ng"] },
            study_type: { eq: "adhoc" },
            cpi: { gte: 1 },
          },
        ],
      },
    };

    try {
      const response = await axios(options);
      setResult(JSON.stringify(response.data, null, 2));
    } catch (error) {
      setResult(
        `Error: ${error instanceof Error ? error.message : String(error)}`
      );
    } finally {
      setLoading(false);
    }
  };

  console.log("reponsibilities: ", opportunities)

  const handleOpportunityStatusChange = async (projectId: number, status: 'LIVE' | 'PAUSED') => {
    try {
      await cintService.updateOpportunityStatus(projectId, status);
      
      // Update the local state to reflect the change
      setOpportunities(opportunities.map(opp => 
        opp.project_id === projectId 
          ? { ...opp, status } 
          : opp
      ));

      // Show success message
      // toast.success(`Opportunity ${status === 'LIVE' ? 'started' : 'paused'} successfully`);
    } catch (error) {
      console.error('Failed to update opportunity status:', error);
      // toast.error('Failed to update opportunity status');
    }
  };

  return (
    <DashboardLayout heading="Manage opportunity">
      <div className="flex flex-row items-center w-full justify-between">
        <div className="flex flex-col">
          <p className="md:text-2xl text-xl text-zinc-950 heading-text font-bold">
            Cint Dashboard
          </p>
          <p className="text-sm light-text max-w-3xl">
            View and manage your Cint opportunities
          </p>
        </div>
        <div className="flex flex-row items-center space-x-2">
       
        <div className="flex flex-row items-center space-x-2">
          <div
            onClick={() => setIsEditModalOpen(true)}
            className="flex cursor-pointer flex-row items-center space-x-2 text-sm font-medium bg-[#29A1AF] text-white px-4 rounded-xl"
          >
            <GoPlusCircle />
            <p className="pt-3">Create Opportunities</p>
          </div>
        </div>
        </div>
      </div>

      {/* Tabs Section */}
      <div className="mt-8 space-y-8">
          <>
            {loading ? (
              <div className="space-y-4">
                {[...Array(3)].map((_, index) => (
                  <SkeletonLoader key={index} />
                ))}
              </div>
            ) : error ? (
              <p className="text-red-500">{error}</p>
            ) : opportunities.length === 0 ? (
              <p>No opportunities available.</p>
            ) : (
              <div className="space-y-4 z-0">
                {opportunities.map((opportunity) => (
                  <CintOpportunityItem
                    key={opportunity.project_id}
                    onStatusChange={handleOpportunityStatusChange}
                    opportunity={opportunity}
                    isOpen={openItemId === opportunity.project_id}
                    onToggle={() => handleToggle(opportunity.project_id)}
                  />
                ))}
              </div>
            )}
          </>
      </div>

      {opportunities.length > 0 && (
        <div className="survey-footer">
          <button onClick={() => handlePageChange(currentPage - 1)}>
            <img src={leftArrowIcon} alt={t("surveyList.pagination_prev")} />
          </button>

            {[...Array(metadata.totalPages)].map((_, index) => {
                const pageNumber = index + 1;
                const isCurrentPage = pageNumber === currentPage;
                
                // Show current page, first page, last page, and pages around current
                const showPage = 
                  pageNumber === 1 || 
                  pageNumber === metadata.totalPages || 
                  (pageNumber >= currentPage - 1 && pageNumber <= currentPage + 1);

                if (!showPage) {
                  if (pageNumber === currentPage - 2 || pageNumber === currentPage + 2) {
                    return (
                      <span
                        key={pageNumber}
                        className="relative inline-flex items-center px-4 py-2 text-sm font-semibold text-zinc-700"
                      >
                        ...
                      </span>
                    );
                  }
                  return null;
                }

                return (
                  <button
                    key={pageNumber}
                    onClick={() => handlePageChange(pageNumber)}
                    className={`relative inline-flex items-center px-4 py-2 text-sm font-semibold
                      ${isCurrentPage
                        ? 'z-10 bg-[#29A1AF] text-white focus:z-20 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-[#29A1AF]'
                        : 'text-zinc-900 hover:bg-zinc-50'}`}
                  >
                    {pageNumber}
                  </button>
                );
              })}
          <button onClick={() => handlePageChange(currentPage + 1)}>
            <img src={rightArrowIcon} alt={t("surveyList.pagination_next")} />
          </button>
        </div>
      )}

      {isEditModalOpen && (
        <CreateOpportunitiesModal onClose={() => setIsEditModalOpen(false)} />
      )}
      {isDeleteModalOpen && (
        <DeleteModal
          onClose={() => setDeleteModalOpen(false)}
          onConfirm={confirmDelete}
        />
      )}
    </DashboardLayout>
  );
}

export default CintDashboard;
