import { AxiosInstance } from "axios";
import apiClient from "../../ApiClient";

class UserService {
    private client: AxiosInstance;

    constructor() {
        this.client = apiClient();
    }

    private handleError(error: any): never {
        if (error.response && error.response.data) {
            const errorMessage =
                error.response.data.message ||
                "An unexpected issue occurred. Please try again later.";
            throw new Error(errorMessage);
        } else if (error.message) {
            throw new Error(error.message);
        } else {
            throw new Error("An unexpected issue occurred. Please try again later.");
        }
    }

    // Fetches a list of users with pagination and optional filters for status and search query
    async fetchUsers(page:number,perPage:number,search?:string,status?:string) {
        try {
            const params: Record<string, any> = {};

            if (page !== undefined) params.page = page;
            if (perPage !== undefined) params.perPage = perPage;
            if (search) params.search = search;
            if (status) params.status = status;

            const response:any = await this.client.get('/user/fetch-all',{ params });
            return response.data;
        } catch (error: any) {
            this.handleError(error);
        }
    }


    // Fetch a specific user's details by user ID
    async getUserDetails(userId: number | string) {
        try {
            const response = await this.client.get(`/users/${userId}`);
            return response.data;
        } catch (error: any) {
            this.handleError(error);
        }
    }


    async updateUserStatus(payload: { id: any | any[]; status: string }) {
        try {
            const response = await this.client.patch(`/users/update/status`, payload);
            return response.data;
        } catch (error: any) {
            this.handleError(error);
        }
    }


    async markUsers(payload: { ids: number | string[]; action: "ACTIVE" | "INACTIVE" }) {
        try {
            const response = await this.client.post(`/users/update/mark-users`, payload);
            return response.data;
        } catch (error: any) {
            this.handleError(error);
        }
    }


    async fetchProjectUsers(projectCode: string, page: number, perPage: number) {
        try {
            const response = await this.client.get(
                `/projects/${projectCode}/users?page=${page}&perPage=${perPage}`
            );
            return response.data;
        } catch (error: any) {
            this.handleError(error);
        }
    }

    /**
     * Fetches user growth data over time
     * Returns data showing user registration trends
     */
    async getUserGrowthData({timeframe, country}:{timeframe: 'year' | 'month' | null, country?: string | null}): Promise<any> {
        try {
            const params: any = {};
            if (country) {
                params.country = country; // Only add country if provided
            }
            const response = await this.client.get('/user/growth-data', {
                params
            });
            return response.data;
        } catch (error: any) {
            this.handleError(error);
        }
    }

    async getUserParticipationData(timeframe: 'year' | 'month' = 'month'): Promise<any> {
        try {
            const response = await this.client.get('/projects/user-participation',{
                params: { filterBy: timeframe }
            });
            return response.data;
        } catch (error: any) {
            this.handleError(error);
        }
    }

    /**
     * Fetches user demographic information
     * Returns data about user age groups, gender, and geographical distribution
     */
    async getUserDemographics({country}: {country?: string | null}): Promise<any> {
        try {
            const params: any = {};
            if (country) {
                params.country = country; // Only add country if provided
            }
            const response = await this.client.get('/user/user-demographics', {
                params
            });
            return response.data;
        } catch (error: any) {
            this.handleError(error);
        }
    }
    
    async getYearlyMonthlyData({country}: {country?: string}): Promise<any> {
        try {
            const params: any = {};
            if (country) {
                params.country = country; // Only add country if provided
            }
            const response = await this.client.get('/user/growth/yearly-monthly', {
                params
            });
            return response.data;
        } catch (error: any) {
            this.handleError(error);
        }
    }

    /**
     * Fetches information about top earning users
     * Returns data about users with highest earnings
     */
    async getTopEarners(): Promise<any> {
        try {
            const response = await this.client.get('/user/top-earners',{
                params: {  limit: 10 }
            });
            return response.data;
        } catch (error: any) {
            this.handleError(error);
        }
    }
}

export default UserService;
