import React, { useState } from 'react';
import ReactDOM from 'react-dom';
import '../../Assets/Styles/CreateOpportunitiesModal.css';
import close from '../../Assets/Images/x.svg';

type CreateOpportunitiesModalProps = {
    onClose: () => void;
};

type Opportunity = {
    country_language: { in: string[] };
    study_type?: { eq: string };
    cpi?: { gte: number };
};

const CreateOpportunitiesModal: React.FC<CreateOpportunitiesModalProps> = ({ onClose }) => {
    const [opportunities, setOpportunities] = useState<Opportunity[]>([{
        country_language: { in: [] },
    }]);

    const handleSubmit = (e: React.FormEvent) => {
        e.preventDefault();
        // Here you would typically send the data to your API
        console.log({
            opportunities
        });
    };

    const handleOpportunityChange = (index: number, field: keyof Opportunity, value: any) => {
        const newOpportunities = [...opportunities];
        if (field === 'country_language') {
            newOpportunities[index].country_language.in = value.split(',').map((s: string) => s.trim());
        } else if (field === 'study_type') {
            newOpportunities[index].study_type = { eq: value };
        } else if (field === 'cpi') {
            newOpportunities[index].cpi = { gte: parseFloat(value) };
        }
        setOpportunities(newOpportunities);
    };

    const modalContent = (
        <div className="create-opportunities-modal-overlay" onClick={onClose}>
            <div className="create-opportunities-modal" onClick={(e) => e.stopPropagation()}>
                <div className="modal-header">
                    <h2>Create Opportunities</h2>
                    <button className="close-button" onClick={onClose}>
                        <img src={close} alt="Close" />
                    </button>
                </div>
                <div className="modal-content flex flex-col space-y-2">
                    <form onSubmit={handleSubmit}>
                       
                        
                        {opportunities.map((opportunity, index) => (
                            <div key={index} className="opportunity-form">
                                <div className="form-group">
                                    <label htmlFor={`country_language_${index}`}>Country-Language:</label>
                                    <input
                                        type="text"
                                        id={`country_language_${index}`}
                                        className={`form-input `}
                                        value={opportunity.country_language.in.join(', ')}
                                        onChange={(e) => handleOpportunityChange(index, 'country_language', e.target.value)}
                                        required
                                    />
                                </div>
                                <div className="form-group">
                                    <label htmlFor={`study_type_${index}`}>Study Type:</label>
                                    <input
                                        type="text"
                                        id={`study_type_${index}`}
                                        className={`form-input `}
                                        value={opportunity.study_type?.eq || ''}
                                        onChange={(e) => handleOpportunityChange(index, 'study_type', e.target.value)}
                                    />
                                </div>
                                <div className="form-group">
                                    <label htmlFor={`cpi_${index}`}>CPI:</label>
                                    <input
                                        type="number"
                                        className={`form-input `}
                                        id={`cpi_${index}`}
                                        value={opportunity.cpi?.gte || ''}
                                        onChange={(e) => handleOpportunityChange(index, 'cpi', e.target.value)}
                                        min="0"
                                        step="0.01"
                                    />
                                </div>
                            </div>
                        ))}
                        <div className="button-group flex flex-row items-center space-x-4 ml-auto text-sm mt-4">
                        <button className="bg-zinc-100 text-zinc-700 rounded-lg px-4 py-2 capitalize" onClick={onClose}>Cancel</button>
                        <button type="submit" className="flex cursor-pointer flex-row items-center font-medium bg-[#29A1AF] text-white px-4 py-2 rounded-xl">Create Opportunity</button>
                    </div>
                        
                    </form>
                </div>
            </div>
        </div>
    );

    return ReactDOM.createPortal(
        modalContent,
        document.body
    );
};

export default CreateOpportunitiesModal;