import React from 'react';
import { Bar } from 'react-chartjs-2';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Tooltip,
    Legend,
} from 'chart.js';

ChartJS.register(CategoryScale, LinearScale, BarElement, Tooltip, Legend);

interface AgeGroup {
    range: string;
    count: number;
}

interface MonthlyData {
    month: number;
    totalUsers: number;
    maleCount: number;
    femaleCount: number;
    ageDistribution?: AgeGroup[];
    countries?: { country: string; count: number }[];
}

interface YearlyData {
    year: number;
    months: MonthlyData[];
}

interface UserGrowthChartProps {
    data: YearlyData[] | null;
    loading: boolean;
}

const UserGrowthChart: React.FC<UserGrowthChartProps> = ({ data, loading }) => {
    // Check if data is an array and not empty
    if (!data || !Array.isArray(data) || data.length === 0) {
        return <div className="p-5 h-[300px] flex items-center justify-center">No data available.</div>;
    }

    // Flatten months data from all years
    const monthsData = data.flatMap((yearData) => yearData.months);

    // Convert month numbers to month names for labels
    const monthLabels = monthsData.map((monthData) => {
        const month = Math.min(Math.max(monthData.month - 1, 0), 11); // Ensures month is within 0-11
        const date = new Date(2023, month); // Set year to a placeholder like 2023
        return date.toLocaleString('default', { month: 'long' });
    });

    // Check if there's data and at least one month has an ageDistribution
    const ageRanges = monthsData[0]?.ageDistribution?.map((age) => age.range) || [];

    // Aggregate user counts by age range for each month
    const datasets = ageRanges.map((range, index) => ({
        label: range,
        data: monthsData.map((monthData) => {
            const ageData = monthData.ageDistribution?.find((age) => age.range === range);
            return ageData ? ageData.count : 0; // Use 0 if ageData is undefined
        }),
        backgroundColor: `rgba(${42 + index * 40}, ${145 - index * 10}, ${157 - index * 20}, 0.8)`, // Different color for each age range
    }));

    // Chart data configuration
    const chartData = {
        labels: monthLabels,
        datasets,
    };

    // Chart options configuration
    const options = {
        responsive: true,
        plugins: {
            legend: {
                position: 'top' as const,
            },
            tooltip: {
                callbacks: {
                    label: (context: any) => {
                        const ageRange = context.dataset.label;
                        const value = context.raw;
                        return `${ageRange}: ${value.toLocaleString()} users`;
                    },
                },
            },
        },
        scales: {
            x: {
                stacked: true,
                title: {
                    display: true,
                    text: 'Month',
                },
            },
            y: {
                stacked: true,
                title: {
                    display: true,
                    text: 'User Count',
                },
            },
        },
    };

    return (
        <div className="bg-white rounded-lg shadow-sm p-6">
            {loading ? (
                <p>Loading...</p>
            ) : (
                <>
                    <h2 className="text-xl font-semibold text-[#3E3F66] mb-6">User Growth by Age Group Over the Months</h2>
                    <Bar data={chartData} options={options} />
                </>
            )}
        </div>
    );
};

export default UserGrowthChart;
