import getUserIpAddress from './getUserIpAddress';
import getGeoIpData from './getGeoIpData';
import { GeoIpResult } from '../../Types';

const getGeoIp = async (): Promise<GeoIpResult> => {
    const ipAddress = await getUserIpAddress();
    if (!ipAddress) {
        return { data: null, subdivisionCode: null, postalCode: null };
    }
    return await getGeoIpData(ipAddress);
};

export default getGeoIp;
