// Similar structure for other status pages: ScreenOut.tsx, QuotaFull.tsx, etc.
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { useLanguage } from '../../Hooks/useLanguage';
import QualificationService from '../../Api/Routes/Qualification/Qualification';
import LoadingOverlay from '../../Components/LoadingOverlay';
import CustomAlert from '../../Components/CustomAlert';
import CryptoJS from "crypto-js";

interface TolunaResponseParams {
    SurveyID: string;
    SurveyName?: string;
    WaveID: string;
    MemberCode: string;
    completionStatus: string;
    Source: string;
    customParam?: string;
    Status: string;
    [key: string]: string | undefined;
}

interface TolunaRedirectLayoutProps {
    status: string;
    tolunaStatus?:string
}


const TolunaRedirectLayout: React.FC<TolunaRedirectLayoutProps> = ({ status, tolunaStatus }: TolunaRedirectLayoutProps) => {
    const { t } = useTranslation();
    const [searchParams] = useSearchParams();
    const surveyId = searchParams.get('SurveyID');

    // Copy this into your browser console
// function createTolunaTestUrl(status:string) {
//     // Test data object
//     const testData = {
//         projectCode: 'TEST123',
//         tolunaStatus: status
//     };
    
//     // Encrypt the data (using 'default_key' for testing)
//     const encrypted = CryptoJS.AES.encrypt(
//         JSON.stringify(testData),
//         'default_key'
//     ).toString();
    
//     // Create the URL (replace 'en' with your desired language)
//     const url = `/en/tln/survey-result?data=${encodeURIComponent(encrypted)}`;
    
//     console.log('Test URL:', url);
//     return url;
// }

// // Test URLs for different statuses
// const testUrls = {
//     completed: createTolunaTestUrl('COMPLETED'),
//     screenOut: createTolunaTestUrl('SCREEN_OUT'),
//     quotaFull: createTolunaTestUrl('QUOTA_FULL'),
//     maxSurvey: createTolunaTestUrl('MAX_SURVEY_REACHED'),
//     surveyTaken: createTolunaTestUrl('SURVEY_TAKEN'),
//     noSurveys: createTolunaTestUrl('NO_SURVEYS'),
//     noCookies: createTolunaTestUrl('NO_COOKIES'),
//     notAvailable: createTolunaTestUrl('SURVEY_NOT_AVAILABLE')
// };

// console.log('All test URLs:', testUrls);

    const location = useLocation();
    const navigate = useNavigate();
    const { language } = useLanguage();
    const [loading, setLoading] = useState(true);
    const [alert, setAlert] = useState<{ type: 'error' | 'success'; message: string } | null>(null);
    const qualificationService = new QualificationService();

    useEffect(() => {
        const queryParams = new URLSearchParams(location.search);
        const params: TolunaResponseParams = {
            SurveyID: surveyId || '',
            WaveID: queryParams.get('WaveID') || '',
            MemberCode: queryParams.get('MemberCode') || '',
            completionStatus: queryParams.get('completionStatus') || '',
            Source: queryParams.get('Source') || '',
            Status: status,
            SurveyName: queryParams.get('SurveyName') || "",
        };

        // http://localhost:3000/en/tln-limit-reached/?Source=toluna&completionStatus=5&SurveyID=dgsdfgsdfg&WaveID=sfdgsdfg&MemberCode=sdfgsfdg&customParam=gsfdgsfdg

        const payload = {
            surveyId: params.SurveyID,
            waveId: params.WaveID,
            memberCode: params.MemberCode,
            source: params.Source,
            status: params.Status,
            SurveyName: params.SurveyName,
            Source: params.Source,
            completionStatus: params.completionStatus
        };

        queryParams.forEach((value, key) => {
            if (!(key in params)) {
                params[key] = value;
            }
        });

        const mappedStatus = status;

        const updateRespondentStatus = async () => {
            try {
                await qualificationService.clientCintUpdateRespondentStatus({
                    ...payload,
                    status: mappedStatus
                });
                setLoading(false);
                navigate(`/${language}/tln/survey-result`, { state: { status: tolunaStatus } });
            } catch (error) {
                console.error('Failed to update respondent status:', error);
                setLoading(false);
                setAlert({ type: 'error', message: t('handleRedirect.update_failed') });
            }
        };

        updateRespondentStatus();

        window.history.replaceState({}, document.title, location.pathname);

    }, [location.search, navigate, language, t]);
    return (
        <div>
            {loading && <LoadingOverlay />}
            {alert && <CustomAlert type={alert.type} message={alert.message} onClose={() => setAlert(null)} />}
            {!loading && !alert && <LoadingOverlay />}
        </div>
    )
};

export default TolunaRedirectLayout