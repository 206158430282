import { ReactNode, useState } from "react";
import "../../Assets/Styles/DashboardLayout.css";
import Sidebar from "../SideBar/SideBar";
import TopBar from "../TopBar/TopBar";
import FooterV2 from "../Footer/FooterV2";
import { Helmet } from "react-helmet";

type Props = { children: ReactNode; heading?: string };

function DashboardLayout({ children, heading }: Props) {
  const [isNavOpen, setIsNavOpen] = useState(false);
  return (
    <div className="dashboard">
      <Helmet>
        <meta charSet="utf-8" />
        <title>{heading}</title>
      </Helmet>
      <div className="dashboard_sidebar">
        <Sidebar setIsNavOpen={setIsNavOpen} />
      </div>
      <div className="dasboard_container z-40">
        <div className="dashboard_containerNavbar">
          <TopBar isNavOpen={isNavOpen} setIsNavOpen={setIsNavOpen} />
        </div>
        <div className="dashboard_containerBody max-w-7xl w-full mx-auto py-8">{children}</div>
        <div className="dashboard_containerFooter">
          <FooterV2 />
        </div>
      </div>
    </div>
  );
}

export default DashboardLayout;
