import React from 'react';
import ReactDOM from 'react-dom';
import '../../Assets/Styles/CreateOpportunitiesModal.css';
import close from '../../Assets/Images/x.svg';
import { FaExclamationTriangle } from 'react-icons/fa';

type DeleModalProps = {
    onClose: () => void;
    onConfirm: () => void
};


const DeleteModal: React.FC<DeleModalProps> = ({ onClose, onConfirm }) => {
   

    const modalContent = (
        <div className="create-opportunities-modal-overlay" onClick={onClose}>
            <div className="create-opportunities-modal" onClick={(e) => e.stopPropagation()}>
                <div className="modal-header">
                    <h2>Confirm delete</h2>
                    <button className="close-button" onClick={onClose}>
                        <img src={close} alt="Close" />
                    </button>
                </div>
                <div className="modal-content space-y-2">
                    <div className="warning-icon mx-auto text-[#f0ad4e] text-7xl text-center">
                        <FaExclamationTriangle />
                    </div>
                    <p className='text-center text-zinc-500 text-sm'>Are you sure you want to delete <strong className='text-zinc-950 text-md'>{"Opportunity"}</strong>?</p>
                    <p className="warning-text text-[#f0ad4e] bg-[#f0ad4e]/20 text-center my-4 text-sm p-2 rounded-lg">This action cannot be undone.</p>
                    <div className="button-group flex flex-row items-center space-x-4 ml-auto text-sm">
                        <button className="bg-zinc-100 text-zinc-700 rounded-lg px-4 py-2 capitalize" onClick={onClose}>Cancel</button>
                        <button onClick={onConfirm} className='bg-red-600 text-white rounded-lg px-4 py-2 capitalize'>confirm delete</button>
                    </div>
                </div>
            </div>
        </div>
    );

    return ReactDOM.createPortal(
        modalContent,
        document.body
    );
};

export default DeleteModal;