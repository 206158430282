import axios from 'axios';

const getUserIpAddress = async (): Promise<string | null> => {
    try {
        const ipifyUrl = process.env.REACT_APP_API_IPIFY_URL;
        if (!ipifyUrl) {
            console.error('IPIFY URL is not set');
            return null;
        }
        const response = await axios.get(ipifyUrl);
        return response.data.ip;
    } catch (error) {
        console.error('Error fetching IP address:', error);
        return null;
    }
};

export default getUserIpAddress;
