import React, {useEffect} from 'react';
import {Route, Routes, useLocation, useNavigate} from 'react-router-dom';
import ProtectedRoute from "./ProtectedRoutes";
import RedirectIfAuthenticated from "./RedirectIfAuthenticated";
import AdminProtectedRoute from "./AdminProtectedRoute";
import {useLanguage} from "../Hooks/useLanguage";
import CintDashboard from '../Pages/Admin/Cint/CintDashboard';
import PreScreeningCint from '../Pages/PreScreeningCint';
import SurveyResultCint from '../Pages/SurveyResultCint';
import NotQualified from '../Pages/Toluna/Redirects/NotQualified';
import MaxSurveyReached from '../Pages/Toluna/Redirects/MaxSurveyReached';
import SurveyNotAvailable from '../Pages/Toluna/Redirects/SurveyNotAvailable';
import QuotaFull from '../Pages/Toluna/Redirects/QuotaFull';
import Qaulified from '../Pages/Toluna/Redirects/Qualified';
import NoSurveys from '../Pages/Toluna/Redirects/NoSurveys';
import Terminated from '../Pages/Toluna/Redirects/Terminated';
import SurveyTaken from '../Pages/Toluna/Redirects/SurveyTaken';
import NoCookies from '../Pages/Toluna/Redirects/NoCookies';
import TolunaResults from '../Pages/Toluna/TolunaResults';
import CintRewards from '../Pages/Admin/Cint/CintRewards';
import ManagerAnalytics from '../Pages/Admin/ManagerAnalytics';
import SuperAdminProtectedRoute from './SuperAdminProtectedRoutes';



const ForgotPassword = React.lazy(() => import('../Pages/ForgotPassword'));
const Login = React.lazy(() => import('../Pages/Login'));
const Register = React.lazy(() => import('../Pages/Register'));
const ResetPassword = React.lazy(() => import('../Pages/ResetPassword'));
const VerifyAccount = React.lazy(() => import('../Pages/VerifyAccount'));
const VerifyLogin = React.lazy(() => import('../Pages/VerifyLogin'));
const EmailSuccess = React.lazy(() => import('../Pages/EmailSuccess'));
const PasswordResetSuccessful = React.lazy(() => import('../Pages/PasswordResetSuccesful'));
const Dashboard = React.lazy(() => import('../Pages/Dashboard'));
const ProfileInfo = React.lazy(() => import('../Pages/Profile'));
const NotificationsPage = React.lazy(() => import('../Pages/Notifications'));
const SecurityPage = React.lazy(() => import('../Pages/Security'));
const ExtraDetails = React.lazy(() => import('../Pages/ExtraDetails'));
const Rewards = React.lazy(() => import('../Pages/Rewards'));
const Profiling = React.lazy(() => import('../Pages/Profiling'));
const Payouts = React.lazy(() => import('../Pages/Payouts'));
const MobileTopUp = React.lazy(() => import('../Pages/MobileTopUp'));
const BankTransfer = React.lazy(() => import('../Pages/BankTransfer'));
const MobileMoney = React.lazy(() => import('../Pages/MobileMoney'));
const NotificationsMainPage = React.lazy(() => import('../Pages/NotificationsPage'));
const NotFoundPage = React.lazy(() => import('../Pages/NotFoundPage'));
const InviteFriends = React.lazy(() => import('../Pages/InviteFriends'));
const InviteHandler = React.lazy(() => import('../Pages/InviteHandler'))
const TermsAndConditions = React.lazy(() => import('../Pages/Terms'));
const PrivacyPolicy = React.lazy(() => import('../Pages/Privacy'));
const UserSecurity = React.lazy(() => import('../Pages/UserSecurity'));
const AccountDeleted = React.lazy(() => import('../Pages/AccountDeleted'));
const AccountRecovery = React.lazy(() => import('../Pages/AccountRecovery'));
const ProjectDashboard = React.lazy(() => import('../Pages/Admin/ProjectDashboard'));
const ProjectStepOne = React.lazy(() => import('../Pages/Admin/ProjectStepOne'));
const InitialWaveCAWI = React.lazy(() => import('../Pages/Admin/InitialWaveCAWI'));
const IncrementWaveCAWI = React.lazy(() => import('../Pages/Admin/IncrementWaveCAWI'));
const RecontactWaveCAWI = React.lazy(() => import('../Pages/Admin/RecontactWaveCAWI'));
const CAPIProjectSetup = React.lazy(() => import('../Pages/Admin/CAPIProjectSetup'));
const MainProjectOverview = React.lazy(() => import('../Pages/Admin/MainProjectOverview'));
const ManageScreeningQuestions = React.lazy(() => import('../Pages/Admin/ManageScreeningQuestions'));
const PreScreening = React.lazy(() => import('../Pages/PreScreening'));
const SurveyResult = React.lazy(() => import('../Pages/SurveyResult'));
const HandleRedirect = React.lazy(() => import('../Pages/HandleRedirect'));
const WalletPage = React.lazy(() => import('../Pages/WalletPage'));
const SurveyResponse = React.lazy(() => import('../Pages/SurveyResponse'));
const PayoutLimitsPage = React.lazy(() => import('../Pages/Admin/PayoutLimitsPage'));
const PayoutManagement = React.lazy(() => import('../Pages/Admin/PayoutManagement'));
const FeatureSurveyScreeningPage = React.lazy(() => import('../Pages/FeatureSurveyScreening'));
const Landing = React.lazy(() => import('../Pages/Landing'));
const InflowHandler = React.lazy(() => import('../Pages/InflowHandler'));
const CapiHandler=React.lazy(()=>import('../Pages/Admin/CapiHandler'))
const InflowPreScreening=React.lazy(() => import('../Pages/InflowPreScreening'));
const CapiPreScreening= React.lazy(()=>import('../Pages/Admin/CapiPreScreening'));
const InflowSurveyResult= React.lazy(() => import('../Pages/InflowSurveyResult'));
const FeatureSurveyResult = React.lazy(() => import("../Pages/FeatureSurveyResult"));
const RewardsManagement = React.lazy(() => import("../Pages/Admin/RewardsManagement"));
const ViewUser= React.lazy(()=>import('../Pages/Admin/ViewUserPage'));
const UserManagement= React.lazy(()=>import('../Pages/Admin/UserManagement'));
const HandleFeatureRedirect= React.lazy(()=>import('../Pages/HandleFeatureRedirect'))
const HandleCintRedirect= React.lazy(()=>import('../Pages/HandleCintRedirect'))
const ViewInflowStats= React.lazy(()=>import('../Pages/ProjectInflowStats'))



const AppRoutes = () => {
    const {language} = useLanguage();
    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        const currentPath = location.pathname;
        const pathParts = currentPath.split('/').filter(Boolean);
        const currentLanguage = pathParts[0];

        if (language !== currentLanguage) {
            const newPath = `/${language}/${pathParts.slice(1).join('/')}`;
            navigate(newPath, {replace: true});
        }
    }, [language, location, navigate]);

    return (<Routes>
            <Route path="*" element={<NotFoundPage/>}/>
            <Route path={`/${language}/terms-and-conditions`} element={<TermsAndConditions/>}/>
            <Route path={`/${language}/privacy-policy`} element={<PrivacyPolicy/>}/>
            <Route path={`/${language}/security-policy`} element={<UserSecurity/>}/>
            <Route path={`/${language}/inflow-onboarding/:invitationToken`} element={<InflowHandler/>}/>
            <Route path={`/${language}/capi-onboarding/:invitationToken`} element={<CapiHandler/>}/>
            <Route path={`/${language}/enrollment/inflow`} element={<InflowPreScreening/>}/>
            <Route path={`/${language}/inflow-survey/result`} element={<InflowSurveyResult/>}/>
            <Route path={`/${language}/enrollment/secure`} element={<PreScreening/>}/>
            <Route path={`/${language}/survey-redirect/:status`} element={<HandleRedirect />} />
            <Route path={`/${language}/cint-redirect`} element={<HandleCintRedirect />} />
            {/* // toluna redirects */}
            <Route path={`/${language}/tln-limit-reached`} element={<MaxSurveyReached />} />
            <Route path={`/${language}/tln-not-qualified`} element={<NotQualified />} />
            <Route path={`/${language}/tln-survey-not-available`} element={<SurveyNotAvailable />} />
            <Route path={`/${language}/tln-complete`} element={<Qaulified />} />
            <Route path={`/${language}/tln-no-surveys`} element={<NoSurveys />} />
            <Route path={`/${language}/tln-terminated`} element={<Terminated />} />
            <Route path={`/${language}/tln-quota-full`} element={<QuotaFull />} />
            <Route path={`/${language}/tln-already-taken`} element={<SurveyTaken />} />
            <Route path={`/${language}/tln-cookies-disabled`} element={<NoCookies />} />
            {/* // end of toluna redirects */}
            <Route path={`/${language}/referrals/:projectCode`} element={<ViewInflowStats />} />
            <Route element={<AdminProtectedRoute/>}>
                <Route path={`/${language}/admin/project/dashboard`} element={<ProjectDashboard/>}/>
                <Route path={`/${language}/admin/project/create`} element={<ProjectStepOne/>}/>
                <Route path={`/${language}/admin/project/create/cawi/initial`} element={<InitialWaveCAWI/>}/>
                <Route path={`/${language}/admin/project/create/cawi/increment`} element={<IncrementWaveCAWI/>}/>
                <Route path={`/${language}/admin/project/create/cawi/recontact`} element={<RecontactWaveCAWI/>}/>
                <Route path={`/${language}/admin/project/create/capi`} element={<CAPIProjectSetup/>}/>
                <Route path={`/${language}/admin/project/overview/:projectCode`} element={<MainProjectOverview/>}/>
                <Route path={`/${language}/admin/manage/screening/questions`} element={<ManageScreeningQuestions/>}/>
                <Route path={`/${language}/admin/payout/limits`} element={<PayoutLimitsPage/>}/>
                <Route path={`/${language}/admin/payout/management`} element={<PayoutManagement/>}/>
                <Route path={`/${language}/admin/rewards/management`} element={<RewardsManagement/>}/>
                <Route path={`/${language}/admin/user/management`} element={<UserManagement/>}/>
                <Route path={`/${language}/admin/user/view`} element={<ViewUser/>}/>
                <Route path={`/${language}/admin/cint/rewards`} element={<CintRewards/>}/>
                <Route path={`/${language}/admin/cint`} element={<CintDashboard/>}/>
            </Route>
            <Route element={<SuperAdminProtectedRoute/>}>
                <Route path={`/${language}/admin/analytics`} element={<ManagerAnalytics/>}/>
            </Route>
            <Route element={<RedirectIfAuthenticated/>}>
                <Route path={`/${language}/login`} element={<Login/>}/>
                <Route path={`/${language}/register`} element={<Register/>}/>
                <Route path={`/${language}/account/verify`} element={<VerifyAccount/>}/>
                <Route path={`/${language}/login/verify`} element={<VerifyLogin/>}/>
                <Route path={`/${language}/email/sent`} element={<EmailSuccess/>}/>
                <Route path={`/${language}/password/forgot`} element={<ForgotPassword/>}/>
                <Route path={`/${language}/invite/friend`} element={<InviteHandler/>}/>
                <Route path={`/${language}/password/reset`} element={<ResetPassword/>}/>
                <Route path={`/${language}/password/reset/successful`} element={<PasswordResetSuccessful/>}/>
                <Route path={`/${language}/account/deleted`} element={<AccountDeleted/>}/>
                <Route path={`/${language}/recover-account`} element={<AccountRecovery/>}/>
                <Route path={`/${language}/`} element={<Landing/>}/>
            </Route>
            <Route element={<ProtectedRoute/>}>
                <Route path={`/${language}/home`} element={<Dashboard/>}/>
                <Route path={`/${language}/profile/complete`} element={<ExtraDetails/>}/>
                <Route path={`/${language}/rewards`} element={<Rewards/>}/>
                <Route path={`/${language}/payouts`} element={<Payouts/>}/>
                <Route path={`/${language}/feeds`} element={<NotificationsMainPage/>}/>
                <Route path={`/${language}/mobile/topup/:encryptedData`} element={<MobileTopUp />} />
                <Route path={`/${language}/bank/transfer/:encryptedData`} element={<BankTransfer />} />
                <Route path={`/${language}/mobile/money/:encryptedData`} element={<MobileMoney />} />
                <Route path={`/${language}/profiling`} element={<Profiling/>}/>
                <Route path={`/${language}/profile`} element={<ProfileInfo/>}/>
                <Route path={`/${language}/notifications`} element={<NotificationsPage/>}/>
                <Route path={`/${language}/security`} element={<SecurityPage/>}/>
                <Route path={`/${language}/invite`} element={<InviteFriends/>}/>
                <Route path={`/${language}/enrollment/secure`} element={<PreScreening/>}/>
                <Route path={`/${language}/cint-enrollment/feature`} element={<PreScreeningCint/>}/>
                <Route path={`/${language}/toluna-enrollment/feature`} element={<PreScreeningCint/>}/>
                <Route path={`/${language}/enrollment/feature`} element={<FeatureSurveyScreeningPage/>}/>
                <Route path={`/${language}/enrollment/capi`} element={<CapiPreScreening/>}/>
                <Route path={`/${language}/wallet`} element={<WalletPage/>}/>
                <Route path={`/${language}/survey/result`} element={<SurveyResult/>}/>
                <Route path={`/${language}/tln/survey-result`} element={<TolunaResults/>}/>
                <Route path={`/${language}/cint/survey/result`} element={<SurveyResultCint/>}/>
                <Route path={`/${language}/feature/survey/result`} element={<FeatureSurveyResult/>}/>
                <Route path={`/${language}/feature/survey-redirect/:status`} element={<HandleFeatureRedirect />} />
                <Route path={`/${language}/survey/internal/c/start`} element={<SurveyResponse/>}/>
            </Route>
        </Routes>);
};

export default AppRoutes;
