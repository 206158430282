import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate, matchPath } from "react-router-dom";
import "../../Assets/Styles/Sidebar.css";
import logo from "../../Assets/Images/logo-white.svg";
import barChart from "../../Assets/Images/bar-chart.svg";
import dollarSign from "../../Assets/Images/sidebar-dollar-sign.svg";
import giftReward from "../../Assets/Images/sidebar-gift.svg";
import settings from "../../Assets/Images/sidebar-settings.svg";
import checkUser from "../../Assets/Images/sidebar-profiling.svg";
import logoutIcon from "../../Assets/Images/sidebar-logout.svg";
import adminIcon from "../../Assets/Images/sidebar-admin.svg";
import walletIcon from "../../Assets/Images/sidebar-wallet.svg";
import rewardsIcon from "../../Assets/Images/sidebar-rewards.svg";
import accountingIcon from "../../Assets/Images/sidebar-payout.svg";
import dataSetIcon from "../../Assets/Images/sidebar-admin-data-market.svg";
import cintIcon from "../../Assets/Images/sidebar-cint.svg"
import usersIcon from "../../Assets/Images/users.svg"
import useLogout from "../../Hooks/useLogout";
import { useLanguage } from "../../Hooks/useLanguage";
import { useTranslation } from "react-i18next";
import AdminService from "../../Api/Routes/Admin/AdminService";
import LoadingOverlay from "../../Components/LoadingOverlay";
import CustomAlert from "../../Components/CustomAlert";
import { useDispatch, useSelector } from "react-redux";
import { RootState, UserRole } from "../../Types";
import { updateUserRole } from "../../Store/slices/user.slice";
import { FaXmark, FaChevronLeft, FaChevronRight } from "react-icons/fa6";

interface SidebarProps {
  setIsNavOpen?: any;
}

const Sidebar: React.FC<SidebarProps> = ({ setIsNavOpen }) => {
  const { language } = useLanguage();
  const location = useLocation();
  const logout = useLogout();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const role = useSelector((state: RootState) => state.user.profile?.role);
  const [loading, setLoading] = useState<boolean>(true);
  const [alert, setAlert] = useState<{
    type: "success" | "error" | "warning" | "info";
    message: string;
  } | null>(null);
  const [isCollapsed, setIsCollapsed] = useState<boolean>(false);

  useEffect(() => {
    if (!role) {
      const fetchRole = async () => {
        setLoading(true);
        try {
          const adminService = new AdminService();
          const response = await adminService.fetchUserRole();
          dispatch(updateUserRole(response.data.role as UserRole));
        } catch (error: any) {
          setAlert({
            type: "error",
            message:
                error.message ||
                "An error occurred while fetching role. Please try again later.",
          });
        } finally {
          setLoading(false);
        }
      };
      fetchRole();
    } else {
      setLoading(false);
    }
  }, [dispatch, role]);

  const isActive = (path: string, exact = false): boolean => {
    return matchPath({ path, end: exact }, location.pathname) !== null;
  };

  const handleInvite = () => {
    navigate(`/${language}/invite`);
  };

  const toggleSidebar = () => {
    setIsCollapsed(!isCollapsed);
  };

  const getLabelForGroup = (baseLabel: string, paths: string[], labels: string[]) => {
    for (let i = 0; i < paths.length; i++) {
      if (isActive(paths[i])) {
        return labels[i];
      }
    }
    return baseLabel;
  };

  const isGroupActive = (paths: string[]) => {
    return paths.some(path => isActive(path));
  };

  if (loading) {
    return <LoadingOverlay />;
  }

  return (
      <aside className={`sidebar relative ${isCollapsed ? "collapsed" : ""}`}>
        {alert && (
            <CustomAlert
                type={alert.type}
                message={alert.message}
                onClose={() => setAlert(null)}
            />
        )}
        <div className="md:hidden flex items-center w-full flex-col">
          <div
              onClick={() => setIsNavOpen(false)}
              className="sidebar_close p-2  bg-white self-end rounded-full "
          >
            <FaXmark />
          </div>
        </div>
        <div className="logo">
          <img src={logo} alt="Logo" className="sidebar-logo" />
        </div>
        <button className="collapse-button " onClick={toggleSidebar}>
          {isCollapsed ? (
              <FaChevronRight />
          ) : (
              <FaChevronLeft />
          )}
        </button>
        <nav className="navigation">
          <ul>
            <li className={isActive(`/${language}/home`, true) ? "active" : ""}>
              <Link to={`/${language}/home`} className="sidebar-link">
                <img src={barChart} alt="Dashboard" className="sidebar-icon"/>
                {!isCollapsed && t("sidebar.home")}
              </Link>
            </li>
            <li className={isActive(`/${language}/profiling`) ? "active" : ""}>
              <Link to={`/${language}/profiling`} className="sidebar-link">
                <img src={checkUser} alt="Profiling" className="sidebar-icon"/>
                {!isCollapsed && t("sidebar.profiling")}
              </Link>
            </li>
            <li className={isActive(`/${language}/rewards`) ? "active" : ""}>
              <Link to={`/${language}/rewards`} className="sidebar-link">
                <img src={giftReward} alt="Rewards" className="sidebar-icon"/>
                {!isCollapsed && t("sidebar.rewards")}
              </Link>
            </li>
            <li className={isGroupActive([
              `/${language}/payouts`,
              `/${language}/mobile/topup`,
              `/${language}/bank/transfer`,
              `/${language}/mobile/money`
            ]) ? "active" : ""}>
              <Link to={`/${language}/payouts`} className="sidebar-link">
                <img src={dollarSign} alt="Payouts" className="sidebar-icon"/>
                {!isCollapsed && getLabelForGroup(t("sidebar.payouts"), [
                  `/${language}/mobile/topup`,
                  `/${language}/bank/transfer`,
                  `/${language}/mobile/money`
                ], [
                  t("sidebar.mobile_topup"),
                  t("sidebar.bank_transfer"),
                  t("sidebar.mobile_money")
                ])}
              </Link>
            </li>
            <li className={isGroupActive([
              `/${language}/settings`,
              `/${language}/profile`,
              `/${language}/security`,
              `/${language}/notifications`,
              `/${language}/feeds`,
              `/${language}/invite`
            ]) ? "active" : ""}>
              <Link to={`/${language}/profile`} className="sidebar-link">
                <img src={settings} alt="Settings" className="sidebar-icon"/>
                {!isCollapsed && getLabelForGroup(t("sidebar.settings"), [
                  `/${language}/settings`,
                  `/${language}/profile`,
                  `/${language}/security`,
                  `/${language}/notifications`,
                  `/${language}/feeds`,
                  `/${language}/invite`
                ], [
                  t("sidebar.settings"),
                  t("sidebar.profile"),
                  t("sidebar.security"),
                  t("sidebar.notifications"),
                  t("sidebar.feeds"),
                  t("sidebar.invite")
                ])}
              </Link>
            </li>
            {role === UserRole.SuperAdmin && (
                <li className={isActive(`/${language}/admin/analytics`) ? "active admin-page" : "admin-page"}>
                    <Link to={`/${language}/admin/analytics`} className="sidebar-link">
                        <img src={dataSetIcon} alt="Analytics" className="sidebar-icon" />
                        {!isCollapsed && "Perfomance"}
                    </Link>
                </li>
            )}
            {(role === UserRole.Admin || role === UserRole.SuperAdmin || role === UserRole.Developer) && (
                <li className={isActive(`/${language}/admin/payout/management`) ? "active admin-page" : "admin-page"}>
                  <Link to={`/${language}/admin/payout/management`} className="sidebar-link">
                    <img src={walletIcon} alt="Payout Manager" className="sidebar-icon"/>
                    {!isCollapsed && t("sidebar.payout_management")}
                  </Link>
                </li>
            )}
            {(role === UserRole.Admin || role === UserRole.SuperAdmin || role === UserRole.Developer) && (
                <li className={isActive(`/${language}/admin/rewards/management`) ? "active admin-page" : "admin-page"}>
                  <Link to={`/${language}/admin/rewards/management`} className="sidebar-link">
                    <img src={rewardsIcon} alt="Rewards Manager" className="sidebar-icon"/>
                    {!isCollapsed && `Rewards Manager`}
                  </Link>
                </li>
            )}
            {(role === UserRole.Admin || role === UserRole.SuperAdmin || role === UserRole.Developer) && (
                <li className={isActive(`/${language}/admin/user/management`) ? "active admin-page" : "admin-page"}>
                  <Link to={`/${language}/admin/user/management`} className="sidebar-link">
                    <img src={usersIcon} alt="User Manager" className="sidebar-icon"/>
                    {!isCollapsed && `User Manager`}
                  </Link>
                </li>
            )}
            {(role === UserRole.Admin || role === UserRole.SuperAdmin || role === UserRole.Developer) && (
                <li className={isActive(`/${language}/admin/payout/limits`) ? "active admin-page" : "admin-page"}>
                  <Link to={`/${language}/admin/payout/limits`} className="sidebar-link">
                    <img src={accountingIcon} alt="Payout Limits" className="sidebar-icon"/>
                    {!isCollapsed && t("sidebar.payout_limits")}
                  </Link>
                </li>
            )}
           
            {(role === UserRole.Admin ||
                role === UserRole.SuperAdmin ||
                role === UserRole.ProjectManager ||
                role === UserRole.Developer) && (
                <li
                    className={`admin-page ${
                        isActive(`/${language}/admin/project/dashboard`) ||
                        isActive(`/${language}/admin/project/create`) ||
                        isActive(`/${language}/admin/project/create/cawi/initial`) ||
                        isActive(`/${language}/admin/project/create/cawi/increment`) ||
                        isActive(`/${language}/admin/project/create/cawi/recontact`) ||
                        isActive(`/${language}/admin/project/create/capi`) ||
                        isActive(
                            `/${language}/admin/project/overview/:projectCode`
                        ) ||
                        isActive(`/${language}/admin/manage/screening/questions`)
                            ? "active"
                            : ""
                    }`}
                >
                  <Link
                      to={`/${language}/admin/project/dashboard`}
                      className="sidebar-link"
                  >
                    <img src={adminIcon} alt="Projects" className="sidebar-icon"/>
                    {!isCollapsed && t("sidebar.projects")}
                  </Link>
                </li>
            )}
             {(role === UserRole.Admin || role === UserRole.SuperAdmin || role === UserRole.Developer) && (
                <li className={isActive(`/${language}/admin/cint`) ? "active admin-page" : "admin-page"}>
                  <Link to={`/${language}/admin/cint`} className="sidebar-link">
                    <img src={cintIcon} alt="Cint" className="sidebar-icon"/>
                    {!isCollapsed && "Cint"}
                  </Link>
                </li>
            )}
          </ul>
          <button className="logout" onClick={logout}>
            <img src={logoutIcon} alt="Log out" className="sidebar-bottom-icons"/>
            {!isCollapsed && t("sidebar.logout_button")}
          </button>
        </nav>
      </aside>
  );
};

export default Sidebar;
