import React, {Suspense} from 'react';
import './App.css';
import {BrowserRouter as Router } from 'react-router-dom';
import LoadingOverlay from './Components/LoadingOverlay';
import {I18nextProvider} from 'react-i18next';
import i18n from "./i18n";
import AppRoutes from "./Routes/AppRoutes";
import {ProjectProvider} from "./Context/ProjectContext";
import Zendesk, {ZendeskAPI} from "./Components/CustomerSupport/ZendeskWidget";
import { GoogleOAuthProvider } from '@react-oauth/google';

function App() {
    const handleLoaded = () => {
        ZendeskAPI("messenger", "close");
    };

    const googleClientId = process.env.REACT_APP_GOOGLE_CLIENT_ID || 'google-client-id';

    return (
        <GoogleOAuthProvider clientId={googleClientId}>
            <I18nextProvider i18n={i18n}>
                <ProjectProvider>
                    <Router>
                        <Suspense fallback={<LoadingOverlay/>}>
                            <Zendesk defer zendeskKey={process.env.REACT_APP_ZENDESK_KEY || 'zendesk-key'} onLoaded={handleLoaded} />
                            <AppRoutes />
                        </Suspense>
                    </Router>
                </ProjectProvider>
            </I18nextProvider>
        </GoogleOAuthProvider>
    );
}

export default App;
