import React from 'react';
import { formatRelative, parseISO, startOfDay, differenceInDays, format } from 'date-fns';
import closeIcon from '../../Assets/Images/close-bank-modal.svg';
import '../../Assets/Styles/NotificationModal.css';
import { useNavigate } from "react-router-dom";
import {useLanguage} from "../../Hooks/useLanguage";

interface NotificationModalProps {
    notifications: Notification[];
    loading: boolean;
    error: string;
    isOpen: boolean;
    onClose: () => void;
}

interface Notification {
    id: string;
    message: string;
    date: string;
    actionText: string;
    actionHandler: () => void;
    category: 'info' | 'failed' | 'success';
}

const NotificationModal: React.FC<NotificationModalProps> = ({ notifications, loading, error, isOpen, onClose }) => {
    const navigate = useNavigate();
    interface GroupedNotifications {
        [key: string]: Notification[];
    }

    const visibleNotifications = notifications.slice(0, 5);
    const { language } = useLanguage();

    const groupedNotifications: GroupedNotifications = visibleNotifications.reduce<GroupedNotifications>((acc, notification) => {
        const dateKey: string = startOfDay(new Date(notification.date)).toISOString();
        acc[dateKey] = acc[dateKey] || [];
        acc[dateKey].push(notification);
        return acc;
    }, {});

    const FEEDS = `/${language}/feeds`;

    const formatDate = (date: string): string => {
        const parsedDate = parseISO(date);
        const today = startOfDay(new Date());
        const diffInDays = differenceInDays(today, parsedDate);

        if (diffInDays === 0) {
            return 'Today';
        } else if (diffInDays === 1) {
            return 'Yesterday';
        } else if (diffInDays >= 2 && diffInDays <= 6) {
            return format(parsedDate, 'EEEE');
        } else {
            return formatRelative(parsedDate, new Date());
        }
    };

    const handleViewNotifications = () => {
        navigate(FEEDS);
    };




    return (
        <div className={`notification-modal ${isOpen ? 'open' : ''}`}>
            <div className="notification-header">
                <h3>Notifications</h3>
                <button className="close-button" onClick={onClose}>
                    <img src={closeIcon} alt="Close" />
                </button>
            </div>
            <div className="notification-list">
                {loading && <p className="loading-message">Loading...</p>}
                {error && <p className="error-message">Error: {error}</p>}
                {!loading && !error && visibleNotifications.length === 0 && <p>No notifications</p>}
                {Object.entries(groupedNotifications).map(([date, groupNotifications], index) => (
                    <div key={date}>
                        {index > 0 && <hr />}
                        <h4 className="date-header">{formatDate(date)}</h4>
                        {groupNotifications.map((notification) => (
                            <div className={`notification-item ${notification.category}`} key={notification.id}>
                                <div className="notification-content">
                                    <p className="notification-message">{notification.message}</p>
                                    <p className="notification-date">{formatRelative(parseISO(notification.date), new Date())}</p>
                                </div>
                                <button className="notification-action" onClick={handleViewNotifications}>
                                    {notification.actionText}
                                </button>
                            </div>
                        ))}
                    </div>
                ))}
                {notifications.length > 5 && <div className="view-more" onClick={handleViewNotifications}>View More</div>}
            </div>
        </div>
    );
};

export default NotificationModal;
