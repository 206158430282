import { AxiosInstance, AxiosResponse } from 'axios';
import apiClient from '../../ApiClient';
import { ApiResponse } from '../../../Types';

export interface CintSurveyOpportunity {
  id: number;
  survey_id: number;
  survey_name: string;
  account_name: string;
  country_language: string;
  industry: string | null;
  study_type: string;
  bid_length_of_interview: number;
  bid_incidence: number;
  collects_pii: boolean;
  survey_group_ids: number[];
  is_live: boolean;
  survey_quota_calc_type: string;
  is_only_supplier_in_group: boolean;
  cpi: number;
  total_client_entrants: number;
  total_remaining: number;
  completion_percentage: number;
  conversion: number;
  length_of_interview: number;
  overall_completes: number;
  mobile_conversion: number;
  earnings_per_click: number;
  termination_length_of_interview: number;
  respondent_pids: string[];
  message_reason: string | null;
  rpc: number;
  rank_score: number | null;
  internal_conversion_weight: number | null;
  external_conversion_weight: number | null;
  createdAt: string;
  updatedAt: string;
}

export interface CintReward {
  id: number;
  projectId: number;
  personId: number;
  amount: number;
  currency: string;
  status: 'PENDING' | 'COMPLETED';
  uuid: string;
  createdAt: string;
  projectName?: string;
}

interface RewardsFilter {
  status?: string;
  projectId?: string;
  page?: number;
  limit?: number;
}

class CintService {
    private client: AxiosInstance;

    constructor() {
        this.client = apiClient();
    }

    private handleError(error: any): never {
        if (error.response && error.response.data) {
            const errorMessage =
                error.response.data.message ||
                'An unexpected issue occurred. Please try again later.';
            throw new Error(errorMessage);
        } else if (error.message) {
            throw new Error(error.message);
        } else {
            throw new Error('An unexpected issue occurred. Please try again later.');
        }
    }

    async getOpportunities(
        page: number,
        limit = 10,
        minCpi?: number,
        maxCpi?: number,
        countryLanguage?: string,
        orderByField = "cpi",
        orderByDirection: "asc" | "desc" = "desc"
    ): Promise<ApiResponse> {
        try {
            const params = {
                page,
                limit,
                minCpi,
                maxCpi,
                countryLanguage,
                orderByField,
                orderByDirection,
            };

            const filteredParams = Object.fromEntries(
                Object.entries(params).filter(([_, v]) => v != null)
            );

            const response: AxiosResponse<ApiResponse> = await this.client.get(
                "/cint/opportunities",
                { params: filteredParams }
            );
            return response.data;
        } catch (error: any) {
            return this.handleError(error);
        }
    }

    async updateOpportunityStatus(
        projectId: number, 
        status: "LIVE" | "PAUSED"
    ): Promise<ApiResponse> {
        try {
            const response: AxiosResponse<ApiResponse> = await this.client.post(
                `/cint/opportunities/${projectId}/status`,
                { status }
            );
            return response.data;
        } catch (error: any) {
            return this.handleError(error);
        }
    }

    async getRewards(filter: RewardsFilter): Promise<ApiResponse> {
        try {
            const params = {
                status: filter.status !== 'all' ? filter.status : undefined,
                projectId: filter.projectId !== 'all' ? filter.projectId : undefined,
                page: filter.page || 1,
                limit: filter.limit || 10
            };

            const filteredParams = Object.fromEntries(
                Object.entries(params).filter(([_, v]) => v != null)
            );

            const response: AxiosResponse<ApiResponse> = 
                await this.client.get('/cint/rewards', { params: filteredParams });
            return response.data;
        } catch (error: any) {
            return this.handleError(error);
        }
    }

    async processReward( status: string,uuid: string[], projectCode: string): Promise<ApiResponse> {
        try {
            const response: AxiosResponse<ApiResponse> = 
                await this.client.patch(`/rewards/update/id/cint`, { uuid, status, projectCode });
            return response.data;
        } catch (error: any) {
            return this.handleError(error);
        }
    }

    async processBulkRewards(uuids: string[]): Promise<ApiResponse> {
        try {
            const response: AxiosResponse<ApiResponse> = 
                await this.client.post('/rewards/update/bulk/cint', { uuids });
            return response.data;
        } catch (error: any) {
            return this.handleError(error);
        }
    }

    async getRewardStats(): Promise<ApiResponse> {
        try {
            const response: AxiosResponse<ApiResponse> = 
                await this.client.get('/cint/rewards/stats');
            return response.data;
        } catch (error: any) {
            return this.handleError(error);
        }
    }
}

export default CintService;