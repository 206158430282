import React, { createContext, useState, useContext, ReactNode } from 'react';

interface Interviewer {
    id: number;
    name: string;
}

interface AgeRange {
    min: number;
    max: number;
}

interface CustomCriteria {
    question: string;
    answers: { value: string; label: string }[];
    criteriaKey: string;
    questionType: string;
    answerType: string;
}

interface TargetRelation {
    parent: string;
    children: TargetRelation[];
}

interface ProjectData {
    projectCode?: string;
    projectType: string;
    client: string;
    countryCode: string;
    countryCodes?: string[];
    wave: number;
    description?: string;
    isLive?: boolean;
    status?: string;
    totalCompleteLimit?: number;
    enabledViaWebApp?: boolean;
    enabledForAdmin?: boolean;
    isReadyToRun?: boolean;
    sampleId?: string;
    isRecontact?: boolean;
    surveyLinkTemplate?: string;
    languageRestrictions?: any;
    excludeCriteria?: any;
    deviceRestrictions?: any;
    startDate?: Date | null;
    endDate?: Date | null;
    recontactFrom?: string;
    external?: boolean;
    surveyLinkLive?: string;
    surveyLinkTest?: string;
    interviewers?: Interviewer[];
    cpi?: string;
    ir?: string;
    loi?: string;
    usdAmount?: string;
    localCurrency?: string;
    totalLocalAmount?: string;
    projectManagers?: string[];
    targetAudience: {
        gender: string[];
        ageRanges: AgeRange[];
        customCriteria: CustomCriteria[];
    };
    targetRelations: TargetRelation[];
    waveNumber?: number;
    projectNumber?: number;
    quotaLimit?: number;
    links?: {
        testLink?: string;
        liveLink?: string;
    };
    dates?: {
        startDate?: Date | null;
        endDate?: Date | null;
    };
    criteriaType?: string;
    criteriaValue?: string;
    studyName?: string;
    internalStudyName?: string;
    studyDistribution?: string;
    screeners?: any[];
    cost?: number;
    customProjectCode?: string;
}

interface ProjectContextType {
    projectData: ProjectData;
    setProjectData: React.Dispatch<React.SetStateAction<ProjectData>>;
}

const ProjectContext = createContext<ProjectContextType | undefined>(undefined);

export const ProjectProvider = ({ children }: { children: ReactNode }) => {
    const [projectData, setProjectData] = useState<ProjectData>({
        projectType: '',
        client: '',
        countryCode: '',
        countryCodes: [],
        wave: 1,
        description: '',
        isLive: false,
        status: '',
        totalCompleteLimit: 0,
        enabledViaWebApp: true,
        enabledForAdmin: true,
        isReadyToRun: false,
        sampleId: '',
        isRecontact: false,
        surveyLinkTemplate: '',
        languageRestrictions: [],
        excludeCriteria: [],
        deviceRestrictions: [],
        startDate: null,
        endDate: null,
        recontactFrom: '',
        external: false,
        surveyLinkLive: '',
        surveyLinkTest: '',
        interviewers: [],
        cpi: '',
        ir: '',
        loi: '',
        usdAmount: '',
        localCurrency: '',
        totalLocalAmount: '',
        projectManagers: [],
        targetAudience: {
            gender: [],
            ageRanges: [],
            customCriteria: [],
        },
        targetRelations: [{ parent: 'country', children: [] }],
        waveNumber: undefined,
        projectNumber: undefined,
        quotaLimit: undefined,
        links: {},
        dates: {},
        criteriaType: '',
        criteriaValue: '',
        studyName: '',
        internalStudyName: '',
        studyDistribution: '',
        screeners: [],
        cost: 0,
        customProjectCode: '',
    });

    return (
        <ProjectContext.Provider value={{ projectData, setProjectData }}>
            {children}
        </ProjectContext.Provider>
    );
};

export const useProject = () => {
    const context = useContext(ProjectContext);
    if (!context) {
        throw new Error('useProject must be used within a ProjectProvider');
    }
    return context;
};
