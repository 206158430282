import React from 'react';
import '../../Assets/Styles/FooterV2.css';
import { useTranslation } from "react-i18next";
import { useLanguage } from "../../Hooks/useLanguage";

const FooterV2: React.FC = () => {
    const currentYear = new Date().getFullYear();
    const { t } = useTranslation();
    const { language } = useLanguage();
    const TERMS = `/${language}/terms-and-conditions`;
    const PRIVACY = `/${language}/privacy-policy`;
    const SECURITY = `/${language}/security-policy`;

    return (
        <footer className="footer-v2">
            <div className="footer-v2__content">
                <div className="footer-v2__left">
                    <span>{t('footerV2.copyright_text', { year: currentYear })}</span>
                </div>
                <div className="footer-v2__right">
                    <a href={TERMS} className="footer-v2__link">{t('footerV2.terms_link')}</a>
                    <a href={PRIVACY} className="footer-v2__link">{t('footerV2.privacy_link')}</a>
                    <a href={SECURITY} className="footer-v2__link">{t('footerV2.security_link')}</a>
                </div>
            </div>
        </footer>
    );
};

export default FooterV2;
