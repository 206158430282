import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import enLogin from './Constants/Translations/en/login.json';
import enSidebar from './Constants/Translations/en/sidebar.json';
import enRegister from './Constants/Translations/en/register.json';
import ptLogin from './Constants/Translations/pt/login.json';
import ptSidebar from './Constants/Translations/pt/sidebar.json';
import ptRegister from './Constants/Translations/pt/register.json';
import frLogin from './Constants/Translations/fr/login.json';
import frSidebar from './Constants/Translations/fr/sidebar.json';
import frRegister from './Constants/Translations/fr/register.json';
import enForgotPassword from './Constants/Translations/en/forgotPassword.json';
import ptForgotPassword from './Constants/Translations/pt/forgotPassword.json';
import frForgotPassword from './Constants/Translations/fr/forgotPassword.json';
import enFooter from './Constants/Translations/en/footer.json';
import ptFooter from './Constants/Translations/pt/footer.json';
import frFooter from './Constants/Translations/fr/footer.json';
import enVerifyLogin from './Constants/Translations/en/verifyLogin.json';
import ptVerifyLogin from './Constants/Translations/pt/verifyLogin.json';
import frVerifyLogin from './Constants/Translations/fr/verifyLogin.json';
import enVerifyAccount from './Constants/Translations/en/verifyAccount.json';
import ptVerifyAccount from './Constants/Translations/pt/verifyAccount.json';
import frVerifyAccount from './Constants/Translations/fr/verifyAccount.json';
import enNotFound from './Constants/Translations/en/notFound.json';
import ptNotFound from './Constants/Translations/pt/notFound.json';
import frNotFound from './Constants/Translations/fr/notFound.json';
import enExtraDetails from './Constants/Translations/en/extraDetails.json';
import ptExtraDetails from './Constants/Translations/pt/extraDetails.json';
import frExtraDetails from './Constants/Translations/fr/extraDetails.json';
import enResetPassword from './Constants/Translations/en/resetPassword.json';
import ptResetPassword from './Constants/Translations/pt/resetPassword.json';
import frResetPassword from './Constants/Translations/fr/resetPassword.json';
import enAccountDeleted from './Constants/Translations/en/accountDeleted.json';
import ptAccountDeleted from './Constants/Translations/pt/accountDeleted.json';
import frAccountDeleted from './Constants/Translations/fr/accountDeleted.json';
import enAccountRecovery from './Constants/Translations/en/accountRecovery.json';
import ptAccountRecovery from './Constants/Translations/pt/accountRecovery.json';
import frAccountRecovery from './Constants/Translations/fr/accountRecovery.json';
import enDashboard from './Constants/Translations/en/dashboard.json';
import ptDashboard from './Constants/Translations/pt/dashboard.json';
import frDashboard from './Constants/Translations/fr/dashboard.json';
import enSurveyList from './Constants/Translations/en/surveyList.json';
import ptSurveyList from './Constants/Translations/pt/surveyList.json';
import frSurveyList from './Constants/Translations/fr/surveyList.json';
import enProfiling from './Constants/Translations/en/profiling.json';
import ptProfiling from './Constants/Translations/pt/profiling.json';
import frProfiling from './Constants/Translations/fr/profiling.json';
import enRewards from './Constants/Translations/en/rewards.json';
import ptRewards from './Constants/Translations/pt/rewards.json';
import frRewards from './Constants/Translations/fr/rewards.json';
import enPayoutHero from './Constants/Translations/en/payoutHero.json';
import ptPayoutHero from './Constants/Translations/pt/payoutHero.json';
import frPayoutHero from './Constants/Translations/fr/payoutHero.json';
import enPayout from './Constants/Translations/en/payout.json';
import ptPayout from './Constants/Translations/pt/payout.json';
import frPayout from './Constants/Translations/fr/payout.json';
import enMobileTopUp from './Constants/Translations/en/mobileTopUp.json';
import ptMobileTopUp from './Constants/Translations/pt/mobileTopUp.json';
import frMobileTopUp from './Constants/Translations/fr/mobileTopUp.json';
import enMobileTopUpHero from './Constants/Translations/en/mobileTopUpHero.json';
import ptMobileTopUpHero from './Constants/Translations/pt/mobileTopUpHero.json';
import frMobileTopUpHero from './Constants/Translations/fr/mobileTopUpHero.json';
import enMobileTopUpPage from './Constants/Translations/en/mobileTopUpPage.json';
import ptMobileTopUpPage from './Constants/Translations/pt/mobileTopUpPage.json';
import frMobileTopUpPage from './Constants/Translations/fr/mobileTopUpPage.json';
import enBankHero from './Constants/Translations/en/bankHero.json';
import ptBankHero from './Constants/Translations/pt/bankHero.json';
import frBankHero from './Constants/Translations/fr/bankHero.json';
import enBankTransfer from './Constants/Translations/en/bankTransfer.json';
import ptBankTransfer from './Constants/Translations/pt/bankTransfer.json';
import frBankTransfer from './Constants/Translations/fr/bankTransfer.json';
import enBankTransferForm from './Constants/Translations/en/bankTransferForm.json';
import ptBankTransferForm from './Constants/Translations/pt/bankTransferForm.json';
import frBankTransferForm from './Constants/Translations/fr/bankTransferForm.json';
import enAddBankModal from './Constants/Translations/en/addBankModal.json';
import ptAddBankModal from './Constants/Translations/pt/addBankModal.json';
import frAddBankModal from './Constants/Translations/fr/addBankModal.json';
import enMobileMoney from './Constants/Translations/en/mobileMoney.json';
import ptMobileMoney from './Constants/Translations/pt/mobileMoney.json';
import frMobileMoney from './Constants/Translations/fr/mobileMoney.json';
import enMobileMoneyHero from './Constants/Translations/en/mobileMoneyHero.json';
import ptMobileMoneyHero from './Constants/Translations/pt/mobileMoneyHero.json';
import frMobileMoneyHero from './Constants/Translations/fr/mobileMoneyHero.json';
import enMobileMoneyForm from './Constants/Translations/en/mobileMoneyForm.json';
import ptMobileMoneyForm from './Constants/Translations/pt/mobileMoneyForm.json';
import frMobileMoneyForm from './Constants/Translations/fr/mobileMoneyForm.json';
import enProfile from './Constants/Translations/en/profile.json';
import ptProfile from './Constants/Translations/pt/profile.json';
import frProfile from './Constants/Translations/fr/profile.json';
import enSubMenu from './Constants/Translations/en/subMenu.json';
import ptSubMenu from './Constants/Translations/pt/subMenu.json';
import frSubMenu from './Constants/Translations/fr/subMenu.json';

import enEditProfileModal from './Constants/Translations/en/editProfileModal.json';
import ptEditProfileModal from './Constants/Translations/pt/editProfileModal.json';
import frEditProfileModal from './Constants/Translations/fr/editProfileModal.json';
import enNotifications from './Constants/Translations/en/notifications.json';
import ptNotifications from './Constants/Translations/pt/notifications.json';
import frNotifications from './Constants/Translations/fr/notifications.json';
import enSecurity from './Constants/Translations/en/security.json';
import ptSecurity from './Constants/Translations/pt/security.json';
import frSecurity from './Constants/Translations/fr/security.json';
import enDeleteAccountModal from './Constants/Translations/en/deleteAccountModal.json';
import ptDeleteAccountModal from './Constants/Translations/pt/deleteAccountModal.json';
import frDeleteAccountModal from './Constants/Translations/fr/deleteAccountModal.json';
import enFooterV2 from './Constants/Translations/en/footer-v2.json';
import ptFooterV2 from './Constants/Translations/pt/footer-v2.json';
import frFooterV2 from './Constants/Translations/fr/footer-v2.json';
import enPreScreening from './Constants/Translations/en/preScreening.json';
import ptPreScreening from './Constants/Translations/pt/preScreening.json';
import frPreScreening from './Constants/Translations/fr/preScreening.json';
import enSurveyResults from './Constants/Translations/en/surveyResults.json';
import ptSurveyResults from './Constants/Translations/pt/surveyResults.json';
import frSurveyResults from './Constants/Translations/fr/surveyResults.json';
import enHandleRedirect from './Constants/Translations/en/handleRedirect.json';
import ptHandleRedirect from './Constants/Translations/pt/handleRedirect.json';
import frHandleRedirect from './Constants/Translations/fr/handleRedirect.json';
import enSecondaryNavbar from './Constants/Translations/en/secondaryNavbar.json';
import ptSecondaryNavbar from './Constants/Translations/pt/secondaryNavbar.json';
import frSecondaryNavbar from './Constants/Translations/fr/secondaryNavbar.json';
import enGetStarted from './Constants/Translations/en/getStarted.json';
import ptGetStarted from './Constants/Translations/pt/getStarted.json';
import frGetStarted from './Constants/Translations/fr/getStarted.json';
import enTopUp from './Constants/Translations/en/topUp.json';
import ptTopUp from './Constants/Translations/pt/topUp.json';
import frTopUp from './Constants/Translations/fr/topUp.json';
import enTestimonials from './Constants/Translations/en/testimonials.json';
import ptTestimonials from './Constants/Translations/pt/testimonials.json';
import frTestimonials from './Constants/Translations/fr/testimonials.json';
import enAbout from './Constants/Translations/en/about.json';
import ptAbout from './Constants/Translations/pt/about.json';
import frAbout from './Constants/Translations/fr/about.json';
import enContact from './Constants/Translations/en/contact.json';
import ptContact from './Constants/Translations/pt/contact.json';
import frContact from './Constants/Translations/fr/contact.json';
import enHero from './Constants/Translations/en/hero.json';
import ptHero from './Constants/Translations/pt/hero.json';
import frHero from './Constants/Translations/fr/hero.json';
import enLandingFooter from './Constants/Translations/en/footerLanding.json';
import ptLandingFooter from './Constants/Translations/pt/footerLanding.json';
import frLandingFooter from './Constants/Translations/fr/footerLanding.json';
import enFaqs from './Constants/Translations/en/faqs.json';
import ptFaqs from './Constants/Translations/pt/faqs.json';
import frFaqs from './Constants/Translations/fr/faqs.json';
import enPhoneVerificationStep from './Constants/Translations/en/phoneVerificationStep.json';
import ptPhoneVerificationStep from './Constants/Translations/pt/phoneVerificationStep.json';
import frPhoneVerificationStep from './Constants/Translations/fr/phoneVerificationStep.json';
import enProfileStep from './Constants/Translations/en/profileInfoStep.json';
import ptProfileStep from './Constants/Translations/pt/profileInfoStep.json';
import frProfileStep from './Constants/Translations/fr/profileInfoStep.json';
import enPhoneInputStep from './Constants/Translations/en/phoneInputStep.json';
import ptPhoneInputStep from './Constants/Translations/pt/phoneInputStep.json';
import frPhoneInputStep from './Constants/Translations/fr/phoneInputStep.json';
import arToluna from './Constants/Translations/ar/toluna.json';
import frToluna from './Constants/Translations/fr/toluna.json';
import ptToluna from './Constants/Translations/pt/toluna.json';
import enToluna from './Constants/Translations/en/toluna.json';



import arLogin from './Constants/Translations/ar/login.json';
import arSidebar from './Constants/Translations/ar/sidebar.json';
import arRegister from './Constants/Translations/ar/register.json';
import arForgotPassword from './Constants/Translations/ar/forgotPassword.json';
import arFooter from './Constants/Translations/ar/footer.json';
import arVerifyLogin from './Constants/Translations/ar/verifyLogin.json';
import arVerifyAccount from './Constants/Translations/ar/verifyAccount.json';
import arNotFound from './Constants/Translations/ar/notFound.json';
import arExtraDetails from './Constants/Translations/ar/extraDetails.json';
import arResetPassword from './Constants/Translations/ar/resetPassword.json';
import arAccountDeleted from './Constants/Translations/ar/accountDeleted.json';
import arAccountRecovery from './Constants/Translations/ar/accountRecovery.json';
import arDashboard from './Constants/Translations/ar/dashboard.json';
import arSurveyList from './Constants/Translations/ar/surveyList.json';
import arProfiling from './Constants/Translations/ar/profiling.json';
import arRewards from './Constants/Translations/ar/rewards.json';
import arPayoutHero from './Constants/Translations/ar/payoutHero.json';
import arPayout from './Constants/Translations/ar/payouts.json';
import arMobileTopUp from './Constants/Translations/ar/mobileTopUp.json';
import arMobileTopUpHero from './Constants/Translations/ar/mobileTopUpHero.json';
import arMobileTopUpPage from './Constants/Translations/ar/mobileTopUpPage.json';
import arBankHero from './Constants/Translations/ar/bankHero.json';
import arBankTransfer from './Constants/Translations/ar/bankTransfer.json';
import arBankTransferForm from './Constants/Translations/ar/bankTransferForm.json';
import arAddBankModal from './Constants/Translations/ar/addBankModal.json';
import arMobileMoney from './Constants/Translations/ar/mobileMoney.json';
import arMobileMoneyHero from './Constants/Translations/ar/mobileMoneyHero.json';
import arMobileMoneyForm from './Constants/Translations/ar/mobileMoneyForm.json';
import arProfile from './Constants/Translations/ar/profile.json';
import arSubMenu from './Constants/Translations/ar/subMenu.json';
import arEditProfileModal from './Constants/Translations/ar/editProfileModal.json';
import arNotifications from './Constants/Translations/ar/notifications.json';
import arSecurity from './Constants/Translations/ar/security.json';
import arDeleteAccountModal from './Constants/Translations/ar/deleteAccountModal.json';
import arFooterV2 from './Constants/Translations/ar/footer-v2.json';
import arPreScreening from './Constants/Translations/ar/preScreening.json';
import arSurveyResults from './Constants/Translations/ar/surveyResults.json';
import arHandleRedirect from './Constants/Translations/ar/handleRedirect.json';
import arSecondaryNavbar from './Constants/Translations/ar/secondaryNavbar.json';
import arGetStarted from './Constants/Translations/ar/getStarted.json';
import arTopUp from './Constants/Translations/ar/topUp.json';
import arTestimonials from './Constants/Translations/ar/testimonials.json';
import arAbout from './Constants/Translations/ar/about.json';
import arContact from './Constants/Translations/ar/contact.json';
import arHero from './Constants/Translations/ar/hero.json';
import arLandingFooter from './Constants/Translations/ar/footerLanding.json';
import arFaqs from './Constants/Translations/ar/faqs.json';





const LANGUAGE_STORAGE_KEY = 'user-language';

const resources = {
    en: {
        translation: {
            dashboard: enDashboard,
            login: enLogin,
            sidebar: enSidebar,
            register: enRegister,
            forgotPassword: enForgotPassword,
            footer: enFooter,
            verifyLogin: enVerifyLogin,
            verifyAccount: enVerifyAccount,
            notFound: enNotFound,
            extraDetails: enExtraDetails,
            resetPassword: enResetPassword,
            accountDeleted: enAccountDeleted,
            accountRecovery: enAccountRecovery,
            surveyList: enSurveyList,
            profiling: enProfiling,
            rewards: enRewards,
            payoutHero: enPayoutHero,
            payouts: enPayout,
            mobileTopUp: enMobileTopUp,
            mobileTopUpHero: enMobileTopUpHero,
            mobileTopUpPage: enMobileTopUpPage,
            bankHero: enBankHero,
            bankTransfer: enBankTransfer,
            bankTransferForm: enBankTransferForm,
            addBankModal: enAddBankModal,
            mobileMoney: enMobileMoney,
            mobileMoneyHero: enMobileMoneyHero,
            mobileMoneyForm: enMobileMoneyForm,
            profile: enProfile,
            submenu: enSubMenu,
            editProfileModal: enEditProfileModal,
            notifications: enNotifications,
            security: enSecurity,
            deleteAccountModal: enDeleteAccountModal,
            footerV2: enFooterV2,
            preScreening: enPreScreening,
            surveyResults: enSurveyResults,
            handleRedirect: enHandleRedirect,
            secondaryNavbar: enSecondaryNavbar,
            getStarted: enGetStarted,
            topUp: enTopUp,
            testimonials: enTestimonials,
            about: enAbout,
            contact: enContact,
            hero: enHero,
            footerLanding: enLandingFooter,
            faqs: enFaqs,
            phoneVerificationStep: enPhoneVerificationStep,
            profileStep: enProfileStep,
            phoneInputStep: enPhoneInputStep,
            toluna: enToluna,
        }
    },
    pt: {
        translation: {
            dashboard: ptDashboard,
            login: ptLogin,
            sidebar: ptSidebar,
            register: ptRegister,
            forgotPassword: ptForgotPassword,
            footer: ptFooter,
            verifyLogin: ptVerifyLogin,
            verifyAccount: ptVerifyAccount,
            notFound: ptNotFound,
            extraDetails: ptExtraDetails,
            resetPassword: ptResetPassword,
            accountDeleted: ptAccountDeleted,
            accountRecovery: ptAccountRecovery,
            surveyList: ptSurveyList,
            profiling: ptProfiling,
            rewards: ptRewards,
            payoutHero: ptPayoutHero,
            payouts: ptPayout,
            mobileTopUp: ptMobileTopUp,
            mobileTopUpHero: ptMobileTopUpHero,
            mobileTopUpPage: ptMobileTopUpPage,
            bankHero: ptBankHero,
            bankTransfer: ptBankTransfer,
            bankTransferForm: ptBankTransferForm,
            addBankModal: ptAddBankModal,
            mobileMoney: ptMobileMoney,
            mobileMoneyHero: ptMobileMoneyHero,
            mobileMoneyForm: ptMobileMoneyForm,
            profile: ptProfile,
            submenu: ptSubMenu,
            editProfileModal: ptEditProfileModal,
            notifications: ptNotifications,
            security: ptSecurity,
            deleteAccountModal: ptDeleteAccountModal,
            footerV2: ptFooterV2,
            preScreening: ptPreScreening,
            surveyResults: ptSurveyResults,
            handleRedirect: ptHandleRedirect,
            secondaryNavbar: ptSecondaryNavbar,
            getStarted: ptGetStarted,
            topUp: ptTopUp,
            testimonials: ptTestimonials,
            about: ptAbout,
            contact: ptContact,
            hero: ptHero,
            footerLanding: ptLandingFooter,
            faqs: ptFaqs,
            phoneVerificationStep: ptPhoneVerificationStep,
            profileStep: ptProfileStep,
            phoneInputStep: ptPhoneInputStep,
            toluna: ptToluna,
        }
    },
    fr: {
        translation: {
            dashboard: frDashboard,
            login: frLogin,
            sidebar: frSidebar,
            register: frRegister,
            forgotPassword: frForgotPassword,
            footer: frFooter,
            verifyLogin: frVerifyLogin,
            verifyAccount: frVerifyAccount,
            notFound: frNotFound,
            extraDetails: frExtraDetails,
            resetPassword: frResetPassword,
            accountDeleted: frAccountDeleted,
            accountRecovery: frAccountRecovery,
            surveyList: frSurveyList,
            profiling: frProfiling,
            rewards: frRewards,
            payoutHero: frPayoutHero,
            payouts: frPayout,
            mobileTopUp: frMobileTopUp,
            mobileTopUpHero: frMobileTopUpHero,
            mobileTopUpPage: frMobileTopUpPage,
            bankHero: frBankHero,
            bankTransfer: frBankTransfer,
            bankTransferForm: frBankTransferForm,
            addBankModal: frAddBankModal,
            mobileMoney: frMobileMoney,
            mobileMoneyHero: frMobileMoneyHero,
            mobileMoneyForm: frMobileMoneyForm,
            profile: frProfile,
            submenu: frSubMenu,
            editProfileModal: frEditProfileModal,
            notifications: frNotifications,
            security: frSecurity,
            deleteAccountModal: frDeleteAccountModal,
            footerV2: frFooterV2,
            preScreening: frPreScreening,
            surveyResults: frSurveyResults,
            handleRedirect: frHandleRedirect,
            secondaryNavbar: frSecondaryNavbar,
            getStarted: frGetStarted,
            topUp: frTopUp,
            testimonials: frTestimonials,
            about: frAbout,
            contact: frContact,
            hero: frHero,
            footerLanding: frLandingFooter,
            faqs: frFaqs,
            phoneVerificationStep: frPhoneVerificationStep,
            profileStep: frProfileStep,
            phoneInputStep: frPhoneInputStep,
            toluna: frToluna,
        }
    },
    ar: {
        translation: {
            dashboard: arDashboard,
            login: arLogin,
            sidebar: arSidebar,
            register: arRegister,
            forgotPassword: arForgotPassword,
            footer: arFooter,
            verifyLogin: arVerifyLogin,
            verifyAccount: arVerifyAccount,
            notFound: arNotFound,
            extraDetails: arExtraDetails,
            resetPassword: arResetPassword,
            accountDeleted: arAccountDeleted,
            accountRecovery: arAccountRecovery,
            surveyList: arSurveyList,
            profiling: arProfiling,
            rewards: arRewards,
            payoutHero: arPayoutHero,
            payouts: arPayout,
            mobileTopUp: arMobileTopUp,
            mobileTopUpHero: arMobileTopUpHero,
            mobileTopUpPage: arMobileTopUpPage,
            bankHero: arBankHero,
            bankTransfer: arBankTransfer,
            bankTransferForm: arBankTransferForm,
            addBankModal: arAddBankModal,
            mobileMoney: arMobileMoney,
            mobileMoneyHero: arMobileMoneyHero,
            mobileMoneyForm: arMobileMoneyForm,
            profile: arProfile,
            submenu: arSubMenu,
            editProfileModal: arEditProfileModal,
            notifications: arNotifications,
            security: arSecurity,
            deleteAccountModal: arDeleteAccountModal,
            footerV2: arFooterV2,
            preScreening: arPreScreening,
            surveyResults: arSurveyResults,
            handleRedirect: arHandleRedirect,
            secondaryNavbar: arSecondaryNavbar,
            getStarted: arGetStarted,
            topUp: arTopUp,
            testimonials: arTestimonials,
            about: arAbout,
            contact: arContact,
            hero: arHero,
            footerLanding: arLandingFooter,
            faqs: arFaqs,
            toluna: arToluna,
        }
    }

};

i18n
    .use(initReactI18next)
    .init({
        resources,
        lng: 'en',
        fallbackLng: 'en',
        debug: false,

        interpolation: {
            escapeValue: false,
        },

        react: {
            useSuspense: false
        }
    });

export function changeLanguage(language) {
    if (!resources[language]) {
        language = 'en';
    }
    i18n.changeLanguage(language);
    localStorage.setItem(LANGUAGE_STORAGE_KEY, language);
}

function loadLanguage() {
    const savedLanguage = localStorage.getItem(LANGUAGE_STORAGE_KEY);
    const browserLanguage = window.navigator.language.split('-')[0];
    return savedLanguage || browserLanguage || 'en';
}

changeLanguage(loadLanguage());

export default i18n;
