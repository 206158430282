import React, { useEffect, useState, useCallback } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Helmet } from "react-helmet";
import LoadingOverlay from "../Components/LoadingOverlay";
import CustomAlert from "../Components/CustomAlert";
import CryptoJS from "crypto-js";
import QualificationService from '../Api/Routes/Qualification/Qualification';
import '../Assets/Styles/SurveyResultPage.css';
import screenOutImage from '../Assets/Images/screenout.png';
import completeImage from '../Assets/Images/complete.png';
import { useTranslation } from "react-i18next";
import { useLanguage } from "../Hooks/useLanguage";

const SurveyResultCint: React.FC = () => {
    const { t } = useTranslation();
    const { language } = useLanguage();
    const HOME = `/${language}/home`;
    const location = useLocation();
    const navigate = useNavigate();

    const [loading, setLoading] = useState(true);
    const [alert, setAlert] = useState<{ type: 'success' | 'error' | 'warning' | 'info'; message: string } | null>(null);
    const [status, setStatus] = useState<string | null>(null);

    const qualificationService = new QualificationService();
    const requestInProgress = React.useRef(false);

    const sendStatusToBackend = useCallback(async (projectCode: string, status: string) => {
        if (requestInProgress.current) return;
        requestInProgress.current = true;

        try {
            const encryptedData = CryptoJS.AES.encrypt(
                JSON.stringify({ projectCode, status }),
                process.env.REACT_APP_CRYPTO_KEY || 'default_key'
            ).toString();

            const dataHash = CryptoJS.SHA256(encryptedData).toString();
            if (sessionStorage.getItem(dataHash)) {
                requestInProgress.current = false;
                return;
            }

            await qualificationService.updateCintRespondentStatus(encryptedData);
            sessionStorage.setItem(dataHash, 'true');
        } catch (error: any) {
            setAlert({ type: 'error', message: error.message || t('Cints.failed_to_update_status') });
        } finally {
            setLoading(false);
            requestInProgress.current = false;
        }
    }, [t]);

    const decryptData = useCallback((encryptedData: string) => {
        try {
            const bytes = CryptoJS.AES.decrypt(encryptedData, process.env.REACT_APP_CRYPTO_KEY || 'default_key');
            return JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
        } catch (error) {
            throw new Error(t('surveyResults.invalid_encryption'));
        }
    }, [t]);

    useEffect(() => {
        const processSurveyResult = async () => {
            setLoading(true);
            const queryParams = new URLSearchParams(location.search);
            const data = queryParams.get('data');

            if (data) {
                try {
                    const decryptedData = decryptData(data);
                    let { projectCode, status } = decryptedData;

                    if (status === 'GEO_LOCKED') {
                        setStatus('TARGET_UNSUITABLE');
                    } else {
                        setStatus(status.toUpperCase());
                    }

                    await sendStatusToBackend(projectCode, status.toUpperCase());
                } catch (error: any) {
                    setAlert({ type: 'error', message: error.message || t('surveyResults.invalid_or_expired_link') });
                    setLoading(false);
                    setTimeout(() => {
                        navigate(HOME);
                    }, 5000);
                }
            } else {
                setAlert({ type: 'warning', message: t('surveyResults.status_or_project_code_missing') });
                setLoading(false);
                setTimeout(() => {
                    navigate(HOME);
                }, 5000);
            }
        };

        processSurveyResult();
    }, [location.search, decryptData, sendStatusToBackend, HOME, navigate, t]);

    const getTitle = () => {
        switch (status) {
            case 'COMPLETED':
                return "Survey Completed Successfully!"
            case 'SCREEN_OUT':
                return "Sorry, You Were Screened Out"
            case 'DISQUALIFIED':
                return "Sorry, You Were Screened Out"
            case 'QUOTA_FULL':
                return "Survey Quota Reached"
            case 'TARGET_UNSUITABLE':
                return 'Target Profile Mismatch'
            case 'CLOSED':
                return "Survey Closed"
                case 'BOT_DETECTED':
                    return "Geographic Restriction";
            default:
                return "Survey Status"
        }
    };

    const getMessage = () => {
        switch (status) {
            case 'COMPLETED':
                return (
                    <>
                        <p>{t('surveyResults.survey_completed_message_1')}</p>
                        <p>{t('surveyResults.survey_completed_message_2')}</p>
                        <p>{t('surveyResults.survey_completed_message_3')}</p>
                    </>
                );
            case 'SCREEN_OUT':
            case 'DISQUALIFIED':
                return (
                    <>
                        <p>{t('surveyResults.survey_screen_out_message_1')}</p>
                        <p>{t('surveyResults.survey_screen_out_message_2')}</p>
                    </>
                );
            case 'QUOTA_FULL':
                return (
                    <>
                        <p>{t('surveyResults.survey_quota_full_message')}</p>
                    </>
                );
            case 'CLOSED':
                return (
                    <>
                        <p>{t('surveyResults.survey_closed_message_1')}</p>
                        <p>{t('surveyResults.survey_closed_message_2')}</p>
                    </>
                );
            case 'BOT_DETECTED':
                return (
                    <>
                        <p>{t('surveyResults.survey_bot_detected_message')}</p>
                    </>
                );
            case 'GEO_LOCKED':
                return (
                    <>
                        <p>{t('surveyResults.survey_geo_locked_message_1')}</p>
                        <p>{t('surveyResults.survey_geo_locked_message_2')}</p>
                    </>
                );
            case 'TARGET_UNSUITABLE':
                return (
                    <>
                        <p>{t('surveyResults.survey_target_unsuitable_message_1')}</p>
                        <p>{t('surveyResults.survey_target_unsuitable_message_2')}</p>
                    </>
                );
            default:
                return (
                    <>
                        <p>{t('surveyResults.survey_unknown_status_message')}</p>
                    </>
                );
        }
    };

    const getImage = () => {
        return status === 'COMPLETED' ? completeImage : screenOutImage;
    };

    const getHelmetTitle = () => {
        switch (status) {
            case 'COMPLETED':
                return t('surveyResults.survey_completed_helmet_title');
            case 'SCREEN_OUT':
            case 'DISQUALIFIED':
                return t('surveyResults.survey_screen_out_helmet_title');
            case 'QUOTA_FULL':
                return t('surveyResults.survey_quota_full_helmet_title');
            case 'TARGET_UNSUITABLE':
                return t('surveyResults.survey_target_unsuitable_helmet_title');
            case 'CLOSED':
                return t('surveyResults.survey_closed_helmet_title');
            case 'BOT_DETECTED':
                return t('surveyResults.survey_bot_detected_helmet_title');
            case 'GEO_LOCKED':
                return t('surveyResults.survey_geo_locked_helmet_title');
            default:
                return t('surveyResults.survey_unknown_status_helmet_title');
        }
    };

    return (
        <div className="main-survey-result">
            <Helmet>
                <meta charSet="utf-8" />
                <title>{getHelmetTitle()}</title>
            </Helmet>
            {loading && <LoadingOverlay />}
            {alert && <CustomAlert type={alert.type} message={alert.message} onClose={() => setAlert(null)} />}
            {!loading && (
                <div className="survey-result-container">
                    <img src={getImage()} alt={status || 'RESULT_IMG'} className="survey-result-image" />
                    <div className="survey-result-content">
                        <h1 className="survey-result-title">{getTitle()}</h1>
                        <div className="survey-result-text">{getMessage()}</div>
                        <a href={HOME} className="survey-result-link">{t('surveyResults.survey_go_back_home')}</a>
                    </div>
                </div>
            )}
        </div>
    );
};

export default SurveyResultCint;
