import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';
import { logout as reduxLogout } from '../Store/slices/auth.slice';
import { store } from '../Store/store';
import { resetStore } from '../Store/resetStore';
import { useLanguage } from './useLanguage';

const useLogout = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { language } = useLanguage();

    const handleLogout = async () => {
        try {
            dispatch(reduxLogout());

            // Optionally, if you have an API call to invalidate the session:
            // await AuthService.logout();

            ['accessToken', 'refreshToken', 'jwt'].forEach(cookie => Cookies.remove(cookie, { path: '/' }));
            store.dispatch({ type: 'reset' });
            resetStore();
            localStorage.clear();
            sessionStorage.clear();

            navigate(`/${language}/login`, { replace: true });
        } catch (error) {
            return;
        }
    };

    return handleLogout;
};

export default useLogout;
