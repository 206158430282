import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import 'bootstrap/dist/css/bootstrap.min.css';
import {Provider} from 'react-redux';
import {PersistGate} from 'redux-persist/integration/react';
import {store, persistor} from './Store/store';
import {LanguageProvider} from "./Hooks/useLanguage";


const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);
root.render(
    <React.StrictMode>
        <Provider store={store}>
            <LanguageProvider>
                <PersistGate loading={null} persistor={persistor}>
                    <div className='mainApp'>
                        <App/>
                    </div>
                </PersistGate>
            </LanguageProvider>
        </Provider>

    </React.StrictMode>
);

reportWebVitals();
