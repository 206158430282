import React, { useEffect, useState } from 'react';
import { Navigate, Outlet, useLocation } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import Cookies from 'js-cookie';
import { RootState, UserRole } from '../Types';
import LoadingOverlay from '../Components/LoadingOverlay';
import { useLanguage } from "../Hooks/useLanguage";
import AdminService from '../Api/Routes/Admin/AdminService';
import { updateUserRole } from '../Store/slices/user.slice';
import { handleLogout } from '../Utils/logout';

const SuperAdminProtectedRoute: React.FC = () => {
    const location = useLocation();
    const dispatch = useDispatch();
    const authState = useSelector((state: RootState) => state.auth);
    const role = useSelector((state: RootState) => state.user.profile?.role);
    const { isAuthenticated, jwt, accessToken, refreshToken } = authState;
    const [isLoading, setIsLoading] = useState(true);
    const [isAuthorized, setIsAuthorized] = useState(false);
    const { language } = useLanguage();

    const checkAuth = () => {
        const cookieAccessToken = Cookies.get("accessToken");
        const cookieRefreshToken = Cookies.get("refreshToken");
        const cookieJwt = Cookies.get("jwt");

        return (
            isAuthenticated &&
            jwt &&
            accessToken &&
            refreshToken &&
            cookieAccessToken &&
            cookieRefreshToken &&
            cookieJwt
        );
    };

    useEffect(() => {
        const checkAuthAndRole = async () => {
            if (!checkAuth()) {
                handleLogout();
                setIsAuthorized(false);
                setIsLoading(false);
                return;
            }

            if (role) {
                if (role === UserRole.SuperAdmin) {
                    setIsAuthorized(true);
                } else {
                    setIsAuthorized(false);
                }
                setIsLoading(false);
            } else {
                try {
                    const adminService = new AdminService();
                    const response = await adminService.fetchUserRole();
                    const userRole = response.data.role as UserRole;
                    dispatch(updateUserRole(userRole));
                    if (userRole === UserRole.SuperAdmin) {
                        setIsAuthorized(true);
                    } else {
                        setIsAuthorized(false);
                    }
                } catch (error) {
                    setIsAuthorized(false);
                } finally {
                    setIsLoading(false);
                }
            }
        };

        checkAuthAndRole();
    }, [authState, role, dispatch]);

    if (isLoading) {
        return <LoadingOverlay />;
    }

    if (!isAuthorized) {
        const redirectPath = !isAuthenticated ? `/${language}/login` : `/${language}/`;
        return <Navigate to={redirectPath} state={{ from: location }} replace />;
    }

    return <Outlet />;
};

export default SuperAdminProtectedRoute;
