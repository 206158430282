import React from 'react';

interface PersonDetails {
    country: string;
}

interface TopEarner {
    id: number;
    email: string;
    rewardBalance: number;
    totalPayouts: number;
    totalRewards: number;
    personDetails: PersonDetails;
}

interface TopEarnersTableProps {
    data: TopEarner[] | null;
    loading: boolean;
    type: string;
}

const TopEarnersTable: React.FC<TopEarnersTableProps> = ({ data, loading, type }) => {
    console.log("data: ", data);
    if (!data || data.length === 0) {
        return (
            <div className="text-center py-8 text-gray-500">
                {loading ? "loading..." : "No top earners data available"}
            </div>
        );
    }

    return (
        <div className="overflow-x-auto">
            <table className="min-w-full bg-white">
                <thead className="bg-gray-50">
                    <tr>
                        <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                            Email
                        </th>
                        <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                            Country
                        </th>
                        <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                            Balance
                        </th>
                    </tr>
                </thead>
                <tbody className="bg-white divide-y divide-gray-200">
                    {data.map((earner) => (
                        <tr key={earner.id} className="hover:bg-gray-50">
                            <td className="px-6 py-4 whitespace-nowrap">
                                <div className="flex items-center">
                                    <div className="text-sm font-medium text-gray-900">
                                        {earner.email}
                                    </div>
                                </div>
                            </td>
                            <td className="px-6 py-4 whitespace-nowrap">
                                <div className="text-sm text-gray-500">
                                    {earner.personDetails?.country || 'N/A'}
                                </div>
                            </td>
                            <td className="px-6 py-4 whitespace-nowrap">
                                <div className="text-sm text-gray-900">
                                    ${type === 'balance' ? earner.rewardBalance.toFixed(2) : type === 'payouts' ? earner.totalPayouts.toFixed(2) : earner.totalRewards.toFixed(2)}
                                </div>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
};

export default TopEarnersTable;